@import '../../assets/scss/variables.scss';

.wrapperContent {
  padding: 0 1rem;
  min-width: 32rem;
  z-index: 1000;

  & > div {
    margin: 1rem;
  }

  & > div:first-child {
    margin-top: 0px;
  }
}
