@import '../../assets/scss/variables.scss';

.dropdownRaw {
  min-height: 2.6rem;
  width: 100%;
  line-height: 2.6rem;
  display: flex;
  align-items: center;

  &.clickable,
  &.clickable * {
    cursor: pointer;
  }
}

.dropdownRaw.clickable:hover {
  background-color: $accent-primary-3;
}

.label {
  flex: 1;
}

.expandedIcon {
  padding-right: 1rem;
  color: $core-secondary-4;
}

.showMoreButton {
  padding-right: 1rem;
  font-style: italic;
  font-weight: bold;
  width: 100%;
  justify-content: flex-end;
}

.dropdownMenu {
  max-height: 30rem;
  overflow: auto;
}

.finDropdownMenu {
  max-height: 12rem;
  overflow: auto;
}

.dropdownMenuAggressor {
  max-height: 15rem;
  overflow: auto;
}
