.size {
  display: inline-block;
  line-height: 11px;
  align-items: center;
}

.sizeDropdown {
  display: inline-block;
  div {
    margin-top: 0;
    margin-left: 0;
  }
}

.sizeCheck {
  display: inline-block;
  margin-right: 0;
}

.input {
  width: 47px;
  height: 26px;
  border-radius: 3px;
  border: solid 1px #d8d8d8;
  background-color: white;
  margin-left: 1rem;
}

.sizeInputWrapper {
  display: inline-block;
  margin-top: 0.3rem;
}
