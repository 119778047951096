@import '../../assets/scss/variables.scss';

.rightBorderAdjust {
  border-radius: 0;
}

.depthContent {
  position: relative;
  box-sizing: border-box;
  min-height: 130px;
  height: 100%;
  max-height: 100%;
  width: 100%;
}

.finContent {
  position: relative;
  box-sizing: border-box;
  min-height: 130px;
  height: 155px;
  width: 100%;
  overflow: hidden;
}

.title {
  padding-top: 0.5rem;
  position: relative;
  display: flex;
  align-items: center;
  align-self: flex-start;
}

.popoutTitle {
  position: absolute;
  top: 0.5rem;
  right: 0.75rem;
  height: 2.4rem;
  cursor: pointer;
  color: #b1b1b1;
}

.container {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.4rem;
  flex-wrap: wrap;
  border-bottom: 2px solid #ffffff;
}

.containerPopout {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem 0.4rem 1.5rem;
  flex-wrap: wrap;
  border-bottom: 2px solid #ffffff;
}

// todo fix confusing class names
.popoutContainer {
  height: 100%;
  width: auto;
  margin: 0 1rem;
}

.expandable {
  position: relative;
  flex: 0;
  display: flex;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .expandable {
    flex: 1;
  }
}

.tableContainer {
  display: flex;
  height: calc(100% - 67px);
  padding-bottom: 0.3em;
  gap: 1rem;
}

.securityTitle {
  flex: 0 1 20rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1.5rem;
  &.popoutSecurityTitle {
    width: 100%;
    flex: 1 0 100%;
  }
}

.flex {
  display: flex;
  padding-top: 0.5rem;
}

.mainTitle {
  height: 2.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.6rem;
  color: $textColor;
  text-transform: uppercase;
  padding-left: 1.5rem;
  padding-right: 2.5rem;
  flex: 0 1 20rem;
  white-space: nowrap;
}

.domContainer {
  flex: 0 0 auto;
  overflow-y: scroll;
}

:global {
  .pointer {
    cursor: pointer;
  }
}

.replaceSearch {
  width: 100%;
  -webkit-app-region: no-drag;
}
