@import '../../../assets/scss/variables.scss';

.main {
  display: flex;
  padding: 1rem;

  .content,
  .icon {
    height: 100%;
  }

  .content {
    margin: 0 auto;
    font-size: 14px;
    font-style: italic;
    color: $White;
  }

  .icon {
    width: 13px;
  }
}

.alert {
  background-color: $Orange;
}
