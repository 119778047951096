@import '../../assets/scss/variables.scss';

.expandableComponents {
  height: auto;
}

.expandContent {
  overflow: hidden;
  transition: max-height 0.5s;
}

.expandable {
  max-height: 2000rem;
}

.collapsed {
  overflow: hidden;
  max-height: 0;
}
