@import '../../../assets/scss/variables.scss';

.main {
  width: 100%;
  padding-bottom: 1.5rem;
  background-color: $SecurityInformationColor;

  .title {
    padding-top: 1rem;
    margin-bottom: 2rem;
    span {
      color: $InputContentLabelColor;
    }
  }

  .title,
  .content {
    margin-left: 1.7rem;
    margin-right: 1.7rem;
  }

  h5 {
    padding-top: 1rem;
    font-weight: bold;
  }

  .content {
    display: flex;
    border-top: 1px solid $ButtonBorderColor;
  }

  .contentSecurityInfo,
  .contenttransactionInfo {
    flex: 1;
  }

  .inputContent {
    width: 100%;
    margin: 1rem 1.5rem 0 0rem;

    p {
      opacity: 0.8;
      margin-bottom: 0;
      font-size: 1.2rem;
      color: $InputContentLabelColor;
    }

    .value {
      display: block;
      border: none;
      font-size: 1em;
      width: 100%;
      background: $Gray;
      padding: 0.5rem 0.8rem 0 0;
    }

    .total {
      font-weight: bold;
    }
  }
}
