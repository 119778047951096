@import '../../../assets/scss/variables.scss';

.issuer {
  padding-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .showAllButton {
    color: $core-primary-4;
    text-transform: uppercase;
    background-color: transparent;

    padding: 0.6rem;
    margin: -0.6rem;
    margin-right: 0.4rem;

    &:hover {
      color: inherit;
    }
  }
}
