@import '../../../assets/scss/variables.scss';

.stackSizeTooltipWrapper {
  position: absolute;
  opacity: 0.9;
  overflow: hidden;
  pointer-events: none;
  height: auto;
  width: auto;
}

.stackSizeTooltip {
  background-color: $core-primary-3;
  color: var(--text-color);
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0.5rem;
  border-radius: 0.3rem;
}

.stackSizeTooltip.ag-tooltip-hiding {
  opacity: 0;
}
