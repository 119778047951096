@import '../../../assets/scss/variables.scss';

.userDashboardWrapper {
  max-width: 115rem;
  button {
    display: inline-block;
    margin-right: 2rem;
    margin-bottom: 1rem;
  }
}

.finWrapper {
  width: 100%;
}

.closeWindow {
  float: right;
  cursor: pointer;
  -webkit-app-region: no-drag;
}

.finFooter {
  position: fixed;
  width: calc(100% - 0.4rem);
  bottom: 0;
  -webkit-app-region: no-drag;
}
