@import '../../assets/scss/variables.scss';

.overlay {
  align-content: center;
  background-color: none;
  display: flex;
  right: 350px;
  position: fixed;
  top: 80px;
  pointer-events: none;
}

.wrapper {
  align-self: center;
  background: #fff;
  border-radius: $borderRadiusDefault;
  box-shadow: $shadowDefault;
  max-width: 70rem;
  position: relative;
  max-height: 100%;
  pointer-events: all;
  padding: 0;
}

.header,
.body,
.footer {
  padding: 2rem 0;
}

.header {
  border-bottom: 1px solid $colorBorder;
  display: flex;
  flex-direction: row;
  font-size: 1.2em;
}

.footer {
  border-top: 1px solid $colorBorder;
}

.close {
  cursor: pointer;
  justify-content: flex-end;
  opacity: 0.5;
  position: absolute;
  right: 1rem;
  top: 1rem;

  img {
    width: 1.5rem;
    height: 1.5rem;
  }

  &:hover {
    opacity: 1;
  }
}

.modalMain {
  width: 34rem;
  display: flex;
  flex-direction: column;
  height: auto;
  pointer-events: all;
  border: 1px solid #96989c;
  border-radius: 1rem;
}

.modalMainLastLook {
  display: flex;
  flex-direction: column;
  height: auto;
  pointer-events: all;
  border: 1px solid #b8bcc1;
  border-radius: 1rem;
}

.rectangleTitle {
  width: 100%;
  height: 6.9rem;
  display: flex;
  position: relative;
  -webkit-app-region: drag;

  .minimize {
    cursor: pointer;
    z-index: 100;
    background-color: $primaryBackgroundColor;
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0 0.1rem 0.15rem 0.1rem;
    border-radius: 0.25rem;
    -webkit-app-region: no-drag;
  }
}

.active {
  cursor: initial;
}

@media all and (-ms-high-contrast: none) {
  .active {
    cursor: auto;
  }
}

.inactive {
  cursor: pointer;
}

.sellSecondBgColor {
  background-color: $SellSecondColor;
}

.sellfirstBgColor {
  background-color: $SellFirstColor;
}

.sellSecondColor {
  color: $SellSecondColor;
}

.sellFirstColor {
  color: $SellFirstColor;
}

.sellBorderColorBottom {
  border-bottom: 1px solid $SellSecondColor !important;
}

.buySecondBgColor {
  background-color: $BuySecondColor;
}

.buyfirstBgColor {
  background-color: $BuyFirstColor;
}

.buySecondColor {
  color: $BuySecondColor;
}

.buyFirstColor {
  color: $BuyFirstColor;
}

.buyBorderColorBottom {
  border-bottom: 1px solid $BuySecondColor !important;
}

.titleBox {
  width: 8rem;
  height: 100%;
  text-align: center;
  border-top-left-radius: 1rem;
  -moz-border-radius-topleft: 1rem;
  -webkit-border-top-left-radius: 1rem;
  -webkit-app-region: drag;

  span {
    font-size: 1.5em;
    font-weight: bold;
    color: $White;
    line-height: 6.9rem;
    vertical-align: middle;
  }
}

.titleCenter {
  width: 32rem;
  height: 100%;
  color: $White;
  display: flex;
  align-items: center;
  border-top-right-radius: 1rem;
  -moz-border-radius-topright: 1rem;
  -webkit-border-top-right-radius: 1rem;

  .titleContent {
    padding: 1.5rem;
    font-size: 1em;
    font-weight: 500;
    line-height: normal;

    .opacityContent {
      opacity: 0.7;
    }

    .firstLineContentTitle {
      font-size: 1.2em;
      font-weight: 500;

      span {
        font-weight: normal;
      }

      .issue {
        margin-left: 0.7rem;
      }
    }

    .secondLineContentTitle {
      span:first-child {
        margin-right: 1.3rem;
      }
    }
  }
}

.title {
  overflow: hidden;
  transition-duration: 0.3s;
  transition-delay: 0.2s;
  transition-timing-function: ease-in;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-delay: 0.2s;
  -webkit-transition-timing-function: ease-in;
  -moz-transition-duration: 0.3s;
  -moz-transition-delay: 0.2s;
  -moz-transition-timing-function: ease-in;
  &BoxInactive {
    border-bottom-left-radius: 1rem;
    -webkit-border-bottom-left-radius: 1rem;
  }
  &CenterInactive {
    border-bottom-right-radius: 1rem;
    -webkit-border-bottom-right-radius: 1rem;
  }
}

.titleCurrency {
  width: 10rem;
  height: 100%;

  .currencyLabel {
    margin-top: 2rem;
    font-size: 1em;
    font-weight: 600;
    color: #64677d;
  }

  .currency {
    opacity: 0.8;
    font-size: 2em;
    font-weight: 500;
    color: #000000;
  }
}

.contentExpandable {
  overflow: hidden;
  transition: max-height 0.5s;
}

.expandableModal {
  max-height: 2000px;
}

.collapsedModal {
  overflow: hidden;
  max-height: 0;
}

.aggressorContent {
  height: 25rem;
}

.lastLookContent {
  height: 19rem;
}

.content {
  width: 100%;

  display: flex;

  .leftInfos {
    width: 23rem;
    height: 100%;
    background-color: $Gray;
    .accountLine {
      margin: 1.7rem 0 0 1.5rem;
    }
    .firstLine,
    .secondLine,
    .fourthLine,
    .thirdLine {
      display: flex;
      flex-wrap: nowrap;

      .inputContent {
        flex: 1;
        margin: 1.7rem 1.5rem 0 1.5rem;
        max-width: 9rem;

        label,
        p {
          margin-bottom: 0;
          font-size: 1.2rem;
          font-weight: 500;
          color: $InputContentLabelColor;
        }

        input {
          display: block;
          border: none;
          width: 8rem;
          opacity: 0.8;
          font-size: 1.5em;
          font-weight: 500;
          background: $Gray;

          &::-webkit-inner-spin-button {
            display: none;
          }
        }

        .value {
          font-size: 1.5em;
          width: 100%;
          color: $textColor;

          &.warning {
            color: $Orange;
          }
        }

        .minQty {
          opacity: 0.6;
          color: $InputContentSpanColor;
          font-style: italic;
          font-size: 0.8em;
          margin-top: 0.5em;
          display: inline-block;
        }
      }
    }
  }

  .rightInfos {
    width: 13rem;
    height: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    background-color: $White;
    flex-direction: column;
    flex: 1;

    &.timedOut {
      background-color: $BottomContentTimedOut;
      color: $White;
    }

    .allInPrice {
      width: 8rem;
      height: 5rem;
      margin: 4rem auto 2rem auto;
      text-align: center;

      p {
        margin-bottom: 0;
        font-size: 1.2rem;
        font-weight: 500;
        color: $InputContentLabelColor;
      }

      .value {
        font-size: 1.5em;
        width: 100%;
        font-weight: bold;
        color: $textColor;
        margin-bottom: 0.5rem;

        &.warning {
          color: $Orange;
        }
      }
    }
  }
}

.aggressorPriceHedge {
  padding-top: 0.5rem;
  border-top: 1px solid #ccc;

  .aggressorPriceHedgeOption {
    background-color: #fff;
    border: 1px solid #426097;
    border-radius: 0.3em;
    margin: 1rem auto;
    &:disabled {
      opacity: 0.5;
    }
  }
  .selectedButton {
    border: 1px solid $BottomContentSuccess;
    background-color: #c0e6df;
  }
}

.lastLookPriceHedgeContainer {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #ccc;
  padding: 0.5rem 0;

  .leftSide {
    margin: 0 auto;
    font-size: 11px;
    .title {
      font-size: 15px;
      line-height: 2rem;
    }
  }

  .rightSide {
    display: flex;
    margin: 0 auto;

    .priceHedgeOption {
      background-color: #fff;
      border: 1px solid #426097;
      border-radius: 0.3em;
      margin: 0.5rem;
      &:disabled {
        background-color: rgba(233, 237, 241, 0.5);
        border: 1px solid rgba(66, 96, 151, 0.5);
        color: rgba(34, 34, 34, 0.5);
      }
    }
    .selectedButton {
      border: 1px solid $BottomContentSuccess;
      background-color: #c0e6df;
    }
  }
}

.issuerDescAndSelectAccount {
  width: 100%;
  height: 8rem;

  p {
    display: flex;
    align-items: center;
    width: 100%;
    height: 4rem;

    span {
      width: 40%;
      padding: 2rem;
    }

    .accountSelected,
    .issuerLD {
      width: 60%;
    }

    .accountSelected {
      select {
        width: 100%;
        height: 2rem;
      }
    }
  }
}

.bottom {
  width: 100%;
  height: 100%;

  .bottomContent {
    color: $White;
    width: 100%;
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      width: 100%;
    }

    p:nth-child(1) {
      opacity: 0.8;
    }

    p:nth-child(2) {
      font-size: 1.3em;
      font-weight: bold;
    }
  }

  .bottomContentSuccess {
    background-color: $BottomContentSuccess;
  }

  .bottomContentRejected {
    background-color: $BottomContentRejected;
  }

  .bottomContentPending {
    background-color: $BottomContentPending;
  }

  .bottomContentTimedOut {
    background-color: $BottomContentTimedOut;
  }

  .bottomNotAvailable {
    background-color: $BottomContentNotAvailable;
  }

  .bottomContentCancelAttempt {
    background-color: $BottomContentCancelled;
  }

  .bottomIcn {
    margin: 1rem;
    width: 5rem;
    height: 5rem;
  }

  .bottomText {
    width: 60%;

    p {
      margin: 0.2rem;
    }
  }
}

.timerWrapper {
  align-items: flex-start;
  padding-top: 1rem;
}

.timerActionWrapper {
  display: flex;
  border-top: 1px solid #ccc;
  width: 100%;

  .timerActionCloseButton,
  .timerActionCloseButtonAccepted,
  .timerActionMainButton,
  .timerActionRejectButton {
    padding: 1em 0;
    width: 50%;
    background: none;
    border: none;
    font-size: 1.2rem;
    display: inline-block;
    border-radius: 0;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .timerActionCloseButton {
    width: 100%;
    color: $White;
  }
  .timerActionCloseButtonAccepted {
    width: 100%;
  }

  .timerActionRejectButton {
    border-right: 1px solid #ccc;
  }
}

.modalWrapper {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.8), 3px 3px 8px rgba(0, 0, 0, 0.8);
  border-radius: 1rem;
  animation: transitionShow 0.3s ease-out;
  form {
    height: max-content;
  }
}

@media all and (-ms-high-contrast: none) {
  .modalWrapper {
    box-shadow: 0px 0px 0px;
  }
}

@keyframes transitionShow {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.tooltip {
  position: relative;
  .tooltiptext {
    visibility: hidden;
    width: 115px;
    background-color: #e9edf1;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -57px;
  }
  ::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #e9edf1 transparent transparent transparent;
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  background-color: rgba(233, 237, 241, 0.9);
  color: #656c78;
}
