.countdown {
  position: relative;
  margin: auto;
  text-align: center;
  .countdownNumber {
    color: white;
    display: inline-block;
    line-height: 4rem;
  }
}
.svg {
  position: absolute;
  top: 0;
  right: 0;
  transform: rotateY(-180deg) rotateZ(-90deg);
}

.strokeCountdown {
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 2px;
  stroke: white;
  fill: none;
}

.strokeBackground {
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 2px;
  stroke: #d8d8d8;
  fill: none;
}

.strokeCountdownSmall {
  animation: countdownSmall linear forwards;
}
.strokeCountdownDefault {
  animation: countdown linear forwards;
}
.strokeCountdownBig {
  animation: countdownBig linear forwards;
}

@keyframes countdown {
  from {
    stroke-dashoffset: var(--countdown-timer-start);
  }
  to {
    stroke-dashoffset: var(--countdown-timer-end);
  }
}

@keyframes countdownSmall {
  from {
    stroke-dashoffset: var(--countdown-timer-start-small);
  }
  to {
    stroke-dashoffset: var(--countdown-timer-end-small);
  }
}

@keyframes countdownBig {
  from {
    stroke-dashoffset: var(--countdown-timer-start-big);
  }
  to {
    stroke-dashoffset: var(--countdown-timer-end-big);
  }
}
