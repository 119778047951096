.denied {
  color: white;
  width: fit-content;
  margin: auto;
  font-size: 2em;
}
.wrapper {
  display: flex;
  justify-content: space-between;
  flex: 1;
  max-height: 100%;
}
