:global {
  .loading-error-container {
    padding: 40px;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
  }

  .confirm-text {
    font-size: 14px;
    font-family: monospace;
  }

  .text-right {
    cursor: pointer;
  }

  .dFlex {
    text-align: right;
  }
}
