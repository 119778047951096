@import '../../../assets/scss/variables.scss';

.tradeBlotterWrapper {
  max-width: 115rem;
  button {
    display: inline-block;
    margin-right: 2rem;
    margin-bottom: 1rem;
  }
}

.finWrapper {
  height: calc(100vh - 14px);
}

.close {
  margin-right: 0;
  margin-left: auto;
  cursor: pointer;
  font-size: 1.2em;
  background-color: #fff;
  width: 2rem;
  -webkit-app-region: drag;

  svg {
    -webkit-app-region: no-drag;
  }
}

.finFooter {
  position: fixed;
  width: calc(100% - 0.4rem);
  bottom: 0;
  -webkit-app-region: no-drag;
}

.customDimensions {
  width: 50%;
  height: 75%;
  border-radius: 0;
  top: 4rem;
  overflow: scroll;
}

.blueBackground {
  background-color: #c5e1fe;
}

.confirmText {
  white-space: pre;
  font-family: monospace;
  padding: 1rem;
}
