@import '../../assets/scss/variables.scss';

.button {
  color: $iconColor;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 2.4rem;
  width: 2.4rem;
  justify-content: center;
  transition: transform 300ms;
  border-radius: 1.2rem;
  margin: 0 0.1rem;
  background: rgba(255, 255, 255, 0.01);

  &:hover {
    background-color: $accent-primary-3;
    color: $iconColor;
  }
}

.active {
  background-color: $accent-primary-1;
  border-radius: 1.2rem;
  color: $core-primary;
}

.active:hover {
  background-color: $accent-primary-1;
  color: $core-primary;
}

:global {
  .iconTooltip {
    font-size: 12px !important;
    text-transform: capitalize;
    line-height: 1rem;
    box-shadow: 0 0 4px 0 rgba(68, 76, 95, 0.4);
    &::before {
      z-index: -1 !important;
    }
  }
}
