@import '../../../assets/scss/variables.scss';

.direction {
  text-transform: uppercase;
  font-weight: bold;

  &.sell {
    color: $accent-positive-1;
  }

  &.buy {
    color: $darkRedText;
  }
}

.counteredToppedLabel {
  text-transform: uppercase;
  margin-left: 0.1rem;
}

.descriptionTwo {
  color: #878f97;
}
