@import '../../assets/scss/variables.scss';
.listItem label {
  padding-left: 1rem;
  min-width: 20rem;
}

.dropdown {
  position: relative;
  display: inline-block;
  line-height: 2rem;
  margin-left: 0.5rem;
  background-color: white;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-radius: 0.3em;
  margin-top: 0.3rem;
  max-width: 155px;
  cursor: pointer;
  i {
    line-height: 2rem;
    float: right;
  }

  label {
    margin-right: 1rem;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.menu {
  margin: 0;
  padding: 0;
  right: 0;
  top: 3.5rem;
  position: absolute;
  background-color: white;
  z-index: 1000;
  box-shadow: 0 0 20px 0 rgba(68, 76, 95, 0.2);
}

.menuRight {
  margin: 0;
  padding: 0;
  left: 0;
  top: 3.5rem;
  position: absolute;
  background-color: white;
  z-index: 1000;
  box-shadow: 0 0 20px 0 rgba(68, 76, 95, 0.2);
}

.menuAggressor {
  margin: 0;
  padding: 0;
  right: 0;
  top: 2rem;
  position: absolute;
  background-color: white;
  z-index: 1000;
  box-shadow: 0 0 20px 0 rgba(68, 76, 95, 0.2);
}

.menuSingle {
  margin: 0;
  padding: 0;
  right: 0;
  top: 2rem;
  position: absolute;
  background-color: white;
  z-index: 1000;
  box-shadow: 0 0 20px 0 rgba(68, 76, 95, 0.2);
}

.menuRightSingle {
  margin: 0;
  padding: 0;
  left: 0;
  top: 2rem;
  position: absolute;
  background-color: white;
  z-index: 1000;
  box-shadow: 0 0 20px 0 rgba(68, 76, 95, 0.2);
}

.selectAll {
  background-color: $core-primary;
  margin-top: -0.5rem;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 0.2rem solid $core-primary-2;
}

.AlertWhen {
  background-color: white;
}
