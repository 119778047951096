@import '../../../assets/scss/variables.scss';

.ManagementReports {
  color: white;
  td {
    color: white;
  }
}

.finManagement {
  color: initial;
  margin: 2rem 0 0 0;
  -webkit-app-region: no-drag;
}

.ManagementReportHeader {
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 1.5rem;
  display: inline-block;
}

.closeWindow {
  cursor: pointer;
  -webkit-app-region: no-drag;
}

.finFooter {
  position: fixed;
  width: calc(100% - 0.4rem);
  bottom: 0;
  -webkit-app-region: no-drag;
}
