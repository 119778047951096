@import '../../../assets/scss/variables.scss';

.contentDropDownMenu {
  margin: 0;
  padding: 0;
  top: 3rem;
  position: absolute;
  background-color: white;
  z-index: 9999;
  box-shadow: 0 0.5rem 2.8rem #e5e5e5;

  max-height: 30rem;
  overflow-y: auto;

  strong {
    font-weight: bold;
  }
}

.notInWatchlistTitleWrapper {
  padding: 1.5rem 1rem 0.5rem;
  display: flex;
  width: 28.25rem;

  .notInWatchlistTitle {
    flex: 1;
    border-top: 0.2rem solid $core-primary-2;
    color: $core-secondary-4;
    font-style: italic;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
