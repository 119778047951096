.menuContainer {
  height: 97vh;
  width: 20rem;
  overflow-y: auto;
  border-left: 1px solid #d3d3d3;
}

.menuSection {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding: 1rem;
  font-size: 15px;
  background-color: #f4f4f4;
  border-top: 1px solid #d3d3d3;
  svg {
    cursor: pointer;
  }
}

.dropdownButton {
  cursor: pointer;
  padding: 1rem;
  border-top: 1px solid #d3d3d3;
  &:hover {
    background-color: #f6f6f6;
  }
  &:last-child {
    border-bottom: 1px solid #d3d3d3;
  }
  svg {
    margin-right: 1rem;
    font-size: 1.5rem;
    color: #008d7f;
  }
  a {
    color: #323337;
    text-decoration: none;
  }
}
