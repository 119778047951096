@import '../../../assets/scss/variables.scss';

.tickerWrapper {
  max-width: 115rem;
  button {
    display: inline-block;
    margin-right: 2rem;
    margin-bottom: 1rem;
  }
}

.finTickerWrapper {
  width: 100%;
}

.tickerSearchWrapper {
  width: 20rem;
  margin-bottom: 1rem;
  -webkit-app-region: no-drag;
}

.closeWindow {
  float: right;
  cursor: pointer;
  -webkit-app-region: no-drag;
}
