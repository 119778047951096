.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2rem;

  h1 {
    font-size: x-large;
  }
}

.alertButton {
  margin-left: 0.5rem;
  svg {
    font-size: 1.3rem;
  }
}

.rightSection {
  display: flex;
  align-items: center;

  span,
  button {
    svg {
      height: 1.5rem;
    }
  }

  button {
    text-transform: uppercase;
    color: #eeeeed;
    margin-left: 1em;
    background-color: inherit;
    border-width: 0;
    font-size: inherit;
    padding: 0;
    text-decoration: underline;

    &.activityButton {
      text-decoration: none;
      font-size: 1.3rem;
      margin-left: 0.5em;
    }

    &.cancelAllButton {
      margin-left: 0.5em;
      svg {
        color: #d1344e;
      }
    }
  }
}

.userName {
  font-weight: bold;
  color: #eeeeed;
}

.marketStatus {
  font-weight: bold;
  font-size: 2rem;
  color: red;
}

.adminButton {
  color: #fff;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 2.4rem;
  width: 2.4rem;
  justify-content: center;
  transition: transform 300ms;
  border-radius: 1.2rem;
  margin: 0 0.1rem;

  &:hover {
    color: #fff;
  }
}

.watchlistButton {
  svg {
    font-size: 1.6rem;
  }
}

.dropdownContainer {
  cursor: pointer;

  .dropdownTitle {
    color: #fff;
    padding: 0.5rem;
    border-radius: 1.2rem;
    svg {
      width: 1.5rem;
    }
  }

  .finTitle {
    border-radius: 1.2rem;
    margin: 0 0.5rem;
    svg {
      color: #656c78;
    }
  }

  .dropdownWrapper {
    display: none;
    width: 220px;
    box-shadow: 0 0 20px 0 rgba(68, 76, 95, 0.2);
    margin: 0;
    right: 12;
    top: 5.5rem;
    position: absolute;
    background-color: #fff;
    z-index: 1000;
    border-radius: 1.2rem;
  }

  .finDropdownWrapper {
    display: block;
    top: 4rem;
  }

  .dropdownButton {
    padding: 1rem;
    svg {
      margin-right: 0.5rem;
    }
    &:hover {
      background-color: #f6f6f6;
      border-radius: 1.2rem;
    }
  }
  ul {
    margin-bottom: 0.5rem;
  }

  li {
    div {
      margin: 1rem 1rem 0rem 1rem;
      cursor: default;
      text-transform: uppercase;
      font-weight: 600;
    }
    a {
      padding: 1rem;
      display: block;
      color: #323337;
      text-decoration: none;
      &:hover {
        background-color: #f6f6f6;
        border-radius: 1.2rem;
      }
    }
  }
  .alignIcon {
    display: flex;
    align-items: center;
    svg {
      margin-left: 1rem;
      height: 1.5rem;
    }
    &:hover {
      background-color: #f6f6f6;
      border-radius: 1.2rem;
    }
  }
}

.dropdownContainer:hover .dropdownWrapper {
  position: fixed;
  display: block;
}
