@import '../../assets/scss/variables.scss';
.UserSelect {
  width: 400px;
  z-index: 500;
}

.finSelect {
  width: 285px;
}

.SelectedUserLabel {
  color: white;
}

.hidden {
  display: none !important;
}

.wrapper {
  display: inline-block;
  button {
    margin-left: 1rem;
    border: 1px solid #d3d3d3;
    height: 80%;
    &:hover {
      background-color: #d3d3d3;
    }
  }
  svg {
    cursor: pointer;
  }
}

.inline {
  display: inline-block;
}
