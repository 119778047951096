:root {
  --countdown-timer-start: 0px;
  --countdown-timer-end: 0px;
  --countdown-timer-start-small: 0px;
  --countdown-timer-end-small: 0px;
  --countdown-timer-start-big: 0px;
  --countdown-timer-end-big: 0px;
}

$colorBorder: #ccc;
$borderRadiusDefault: 1rem;
// https://tobiasahlin.com/blog/layered-smooth-box-shadows/
$shadowDefault: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
  0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
  0 16px 16px rgba(0, 0, 0, 0.12);

// General Theme Colors
$DarkFontColor: #1b2c39;
$ActiveBackgroundColor: #e2e9f2;
$primaryBackgroundColor: #ffffff;
$secondaryBackgroundColor: #f4f4f4;

$textColor: #323337;
$hoverColor: #eeeeed;
$darkBlueText: #6a87a9;
$lightBlueText: #98adc6;
$darkRedText: #b45161;
$lightRedText: #d97c8b;

$iconColor: #656c78;
$stopIconColor: #c65757;

$core-primary: #ffffff;
$core-primary-1: #f9f9fa;
$core-primary-2: #f4f4f4;
$core-primary-3: #e2e4e6;
$core-primary-4: #d1d5d8;
$core-primary-5: #b8bcc1;

$core-secondary: #1d303f;
$core-secondary-1: #4a5965;
$core-secondary-2: #616e78;
$core-secondary-3: #77828b;
$core-secondary-4: #a4acb2;
$core-secondary-5: #d1d5d8;

$accent-primary: #344e6d;
$accent-primary-1: #45678e;
$accent-primary-2: #6a85a4;
$accent-primary-3: #e9edf1;

$accent-positive: #307c63;
$accent-positive-1: #40aa80;
$accent-positive-2: #66bb99;
$accent-positive-3: #8cccb2;

// Modal aggressor view color
$SellSecondColor: #a53b4b;
$SellFirstColor: #c17781;
$BuyFirstColor: #6994c2;
$BuySecondColor: #527aa7;
$White: #ffffff;
$Gray: #f4f4f4;
$SecondGray: #878f97;
$LightOrange: #ffe8ca;
$Orange: #ed9b2f;
$BlackFontColor: #000000;
$InputContentLabelColor: #777881;
$InputContentSpanColor: #000000;
$SecurityInformationColor: #f4f4f4;
$BottomContentSuccess: #02b495;
$BottomContentRejected: #811212;
$BottomContentPending: $Orange;
$BottomContentTimedOut: #811212;
$BottomContentCancelled: #811212;
$BottomContentNotAvailable: #44454c;
$BtnCloseAloneSuccess: #c0e6df;
$BtnCloseAlonePending: #fdd093;
$BtnCloseAloneRejected: #e6c0c0;
$BtnCloseColor: #f4f4f4;
$ButtonBorderColor: #d8d8d8;
