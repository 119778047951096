@import '../../assets/scss/variables.scss';
.AlertWrapper {
  width: 406px;
  border-radius: 3px;
  box-shadow: 0 0 20px 0 rgba(68, 76, 95, 0.2);
  margin: 0;
  right: 80;
  top: 8rem;
  position: absolute;
  background-color: white;
  z-index: 1000;
  input[type='checkbox'] {
    margin-right: 0.5rem;
  }
}

.openfinAlertWrapper {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
}

.HeaderWrapper {
  padding: 1rem 1rem 0rem 1.5rem;
}

.button {
  color: #fff;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 2.4rem;
  width: 2.4rem;
  justify-content: center;
  transition: transform 300ms;
  border-radius: 1.2rem;
  margin: 0 0.1rem;

  &:hover {
    color: #fff;
  }
}

.finButton {
  color: $iconColor !important;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  transition: transform 300ms;
  border-radius: 1.2rem;
  margin: 0 0.1rem;

  &:hover {
    color: $iconColor;
  }
}

.active {
  background-color: $accent-primary-1;
  border-radius: 1.2rem;
  svg {
    color: white;
  }
}

.active:hover {
  background-color: $accent-primary-1;
  svg {
    color: white;
  }
}

.RuleList {
  height: 12rem;
  .RuleListItems {
    height: 8.4rem;
    overflow-y: scroll;
  }
}

.HeaderLabel {
  height: 24px;
  font-family: Lato, sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #323337;
}

.ListHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0 0 0;
  &Left {
    label {
      width: 95px;
      height: 24px;
      font-family: Lato, sans-serif;
      font-size: 1.2rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      color: #323337;
    }
  }

  &Right {
    display: flex;
    justify-content: space-between;
    label {
      width: 34px;
      height: 24px;
      font-family: Lato, sans-serif;
      font-size: 1.2rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.18;
      letter-spacing: normal;
      color: #323337;
    }
    .NewButton {
      width: 2rem;
      height: 2rem;
      border-radius: 1.2rem;
      background-color: #d1d5d8;
      font-family: Arial, sans-serif;
      text-align: center;
      cursor: pointer;
    }

    .NewPlus {
      width: 2rem;
      height: 1.3rem;
      opacity: 0.7;
      font-size: 1.2rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      line-height: 2.3rem;
      color: #1b2c39;
    }
  }
}

.RuleName {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0 0 0;
  &Left {
    label {
      width: 95px;
      height: 24px;
      font-family: Lato, sans-serif;
      font-size: 1.2rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      color: #323337;
    }
  }
  &Right {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    label {
      width: 44px;
      font-family: Lato, sans-serif;
      font-size: 1.2rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #323337;
      cursor: pointer;
    }

    .ResetIcon {
      width: 2rem;
      height: 1.2rem;
      opacity: 0.7;
      font-size: 1.2rem;
      line-height: 1rem;
      color: #1b2c39;
    }
  }
}

.NameInput {
  width: 369px;
  height: 26px;
  border-radius: 3px;
  border: solid 1px #d8d8d8;
  background-color: var(--light-core-primary-1);
  padding-left: 0.5rem;
  &::placeholder {
    font-style: italic;
  }
  &:placeholder-shown {
    background: $LightOrange;
  }
}

.ApplyAlertUsingHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0 0 0;
  width: 36.9rem;
  height: 5rem;
  border-bottom: 1px solid #d8d8d8;
  &Left {
    label {
      width: 95px;
      height: 24px;
      font-family: Lato, sans-serif;
      font-size: 1.2rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      color: #323337;
    }
  }
  &Right {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    label {
      font-family: Lato, sans-serif;
      font-size: 1.2rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #323337;
      cursor: pointer;
    }
  }
}

.FilterMethod {
  width: 36.9rem;
  cursor: pointer;
  background-color: #f4f4f4;
  border-bottom: 1px solid #d8d8d8;
  &Left {
    span {
      width: 171px;
      height: 13px;
      font-family: Lato, sans-serif;
      font-size: 1.2rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #323337;
    }
  }
}

.FilterMethodHeader {
  display: flex;
  height: 3rem;
  .angleBackground {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: #323337;
  }

  i {
    width: 12px;
    height: 8px;
    font-stretch: normal;
    font-size: 10px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: black;
    margin: auto;
  }
}

.AlertFilterMethodText {
  padding: 0 0 0 1.5rem;
  line-height: 3rem;
  width: 34rem;
}

.BondList {
  margin-left: 1.5rem;
  margin-bottom: 1.9rem;
}

.BondList p {
  width: 47px;
  height: 20px;
  font-family: Lato, sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #878f97;
}

.BondList textarea {
  resize: none;
  width: 315px;
  height: 104px;
  background-color: #ffffff;
  border-radius: 3px;
  border: solid 1px #d8d8d8;
  font-family: Lato, sans-serif;
  font-size: 10px;
  font-weight: normal;
  &::placeholder {
    font-style: italic;
  }
  padding: 1rem;
}

.WatchlistSelect {
  width: 204px;
  height: 26px;
  border-radius: 3px;
  border: solid 1px #d8d8d8;
  background-color: white;
}

.WatchlistSelect label {
  width: 32px;
  height: 13px;
  font-family: Lato, sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #687078;
}

.WatchlistSelect i {
  float: right;
  margin-right: 0.5rem;
}

.AndOrDropdown {
  min-width: 3rem;
}

.AlertMeWhen {
  padding: 1rem 0 0.5rem 0;
  label {
    width: 92px;
    height: 20px;
    font-family: Lato, sans-serif;
    font-size: 1.2rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.82;
    letter-spacing: normal;
    color: #323337;
  }
}

.AlertMeWhenTable {
  td {
    width: 33%;
    height: 1.5rem;
  }
}

.AndIf {
  padding: 1rem 0 0.5rem 0;
  font-weight: bold;
}

.AlertFilterListItem {
  display: flex;
  justify-content: space-between;
  height: 2.8rem;
  line-height: 2.8rem;
  cursor: pointer;
  border-bottom: 1px solid #d8d8d8;
  &:hover {
    background-color: $accent-primary-3;
  }
  &.selected {
    font-weight: bold;
  }
  .AlertFilterListItemName {
    width: 31rem;
  }
  .AlertFilterListItemIcon {
    color: #687078;
    font-size: 13px;
  }

  .bellContainer {
    width: 3rem;
    margin: auto;
  }

  .DeleteIcon {
    margin: auto;
    font-size: 13px;
  }
}

.none {
  display: none !important;
}

.ConfirmDelete {
  height: 2.8rem;
  line-height: 2.8rem;
  width: 25rem;
}

.btnGrpDeleteWatchlist {
  display: flex;
  align-items: center;
  width: 10rem;
  button {
    height: 100% !important;
    width: 5.5rem !important;
    margin-left: 0 !important;
    text-decoration: none !important;
  }
  .deleteWatchlistConfirmation {
    width: 4rem;
    background-color: rgba($color: #000000, $alpha: 0);
    border: 0;
    border-radius: 0;
    color: white;
  }
  .deleteWatchlistConfirmation:hover {
    background-color: #28c988;
  }
}

.handleConfirmation {
  background-color: $Orange !important;
  color: white !important;

  .handleConfirmation button {
    color: white !important;
  }
}

.sizePriceInput {
  width: 47px;
  height: 26px;
  border-radius: 3px;
  border: solid 1px #d8d8d8;
  background-color: var(--light-core-primary-1);
  margin-left: 1rem;
}

.RuleEdit {
  height: 38rem;
  padding-left: 1.5rem;
  overflow: scroll;
  background-color: #fff;
}

.errorTextWrapper {
  width: 100%;
  height: 2rem;
}

.errorText {
  color: red;
  margin-left: 15px;
}

.notDefault {
  border: 1px solid #426097;
  background-color: #dbe3f2;
  border-radius: 0.3em;
}
