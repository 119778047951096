@import '../../assets/scss/variables.scss';

$primary-color: $primaryBackgroundColor;
$secondary-color: $secondaryBackgroundColor;

:global {
  .bold {
    font-weight: bold;
  }

  .number {
    font-variant-numeric: tabular-nums;
  }

  .normalGray {
    font-weight: normal;
    color: $SecondGray;
  }

  .normal {
    font-weight: normal;
  }

  .normal-buy {
    color: $lightRedText;
  }

  .normal-sell {
    color: $lightBlueText;
  }

  .blueText {
    color: $darkBlueText;
    text-align: right;
  }

  .redText {
    color: $darkRedText;
    text-align: right;
  }

  .ag-theme-balham {
    --ag-header-height: 2rem;
    --ag-selected-row-background-color: #e9edf1 !important;
    --ag-cell-horizontal-padding: 0;
    .ag-ltr {
      color: $textColor;
      background-color: $primaryBackgroundColor;
      border-radius: 0 !important;
      border-left: 0 !important;
      border-right: 0 !important;

      input {
        background-color: $White;
        color: $BlackFontColor;
      }

      .ag-root {
        border: none;
      }

      .ag-layout-normal {
        overflow-x: visible;
        width: auto;
      }

      .ag-pinned-right-header {
        border-left-width: 0 !important;
        width: 60.7rem !important;
        max-width: 60.7rem !important;
        min-width: 60.7rem !important;

        .ag-header-row {
          border-left: 2px solid #e4e5e6 !important;
        }
      }

      .ag-cell-first-right-pinned {
        border-left-width: 2px !important;
        border-left-color: #e4e5e6 !important;
      }

      .ag-pinned-left-header {
        border-right-width: 0 !important;
      }

      .ag-column-select-header {
        display: none;
      }

      .ag-header {
        line-height: 1.6;
        opacity: 0.8;
        color: #878f97;
        background-color: transparent;
        border-bottom: none;

        .ag-header-cell {
          border-bottom: 1px solid #f1f1f1;
          padding-left: 0.3rem;
          padding-right: 0;
          font-weight: normal;

          &:first-child {
            padding-left: 1rem !important;
          }

          .ag-header-cell-label {
            text-align: left;
          }
          .ag-cell-label-container {
            padding: 0 !important;
          }
        }

        .ag-header-cell::after {
          border-right: none;
        }

        .ag-header-cell-text {
          color: #45494e;
          flex: 1;
          font-weight: 500;
        }

        .ag-header-cell:not(.ag-header-group-cell-no-group) {
          border-top-width: 0;
        }
      }

      .ag-pinned-right-cols-container {
        width: 59.3rem !important;
        max-width: 59.3rem !important;
        min-width: 59.3rem !important;
      }

      .ag-cell {
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        font-family: 'Lato', sans-serif;
        font-size: 1.2rem;
        height: 100% !important;
        border-top: 1px solid #f1f1f1;
        border-bottom-width: 0;
        border-right-width: 0;
        border-left-width: 0;
        display: flex;
        justify-content: stretch;
        align-items: center;

        &.noPadding {
          padding-left: 0;
          padding-right: 0;
        }

        .ag-react-container {
          display: flex;
          flex: 1;
          align-items: center;
        }

        &.number > .ag-react-container {
          justify-content: flex-end;
        }

        &:first-child {
          padding-left: 1rem !important;
        }

        &.ag-cell-last-left-pinned {
          border-right-width: 0 !important;
        }

        &.hideIssuer {
          color: transparent;
          border-top-width: 0 !important;
          transition: color 0.3s ease;
          font-weight: normal;
        }
      }

      .ag-cell.ag-cell-inline-editing {
        height: 2rem;
        background: unset;
        background-color: unset;
      }

      .ag-row-level-0 {
        background-color: transparent;
      }

      .ag-row-focus,
      .ag-row-hover {
        background-color: $accent-primary-3;
        color: $textColor;

        .my-orders-edit {
          display: block !important;
          cursor: pointer;
        }

        .my-orders-view {
          display: none !important;
        }

        .checked-security {
          display: block !important;
        }

        .ag-cell.hideIssuer {
          color: darkgray;
        }
      }

      .ag-row-selected {
        .checked-security {
          display: block !important;
        }
      }

      .ag-row {
        display: flex;
        align-items: center;
        //width: auto;

        .ag-cell-wrapper {
          width: 100%;
        }

        .ag-cell-focus,
        .ag-cell-inline-editing,
        .ag-cell {
          line-height: 2rem;
        }

        .ag-cell-focus,
        .ag-cell-inline-editing {
          border-left-width: 0 !important;
          border-right-width: 0 !important;
          border-bottom-width: 0 !important;
        }

        .ag-cell-focus {
          border-top: 1px solid #f1f1f1 !important;

          input {
            margin-top: -1px;
            margin-right: 1px;
          }
        }

        .my-orders-edit {
          display: none;
        }

        .my-orders-view {
          display: block;
          padding: 0 6px;
        }

        .checked-security {
          display: none;
        }
      }

      @media all and (-ms-high-contrast: none) {
        .ag-row {
          align-items: inherit;
        }
      }

      .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
        border-right: none;
        border-bottom: none;
      }
    }
  }

  .ag-overlay-panel {
    justify-content: center;
    background: white;
  }

  .ag-body-viewport {
    margin-top: -1px;
  }

  .ag-tooltip {
    background-color: $core-primary-3 !important;
  }
  .ag-center-cols-clipper {
    padding-right: 10px;
  }
}

.gridStyle {
  flex: 1 0;
  width: 100%;
  display: flex;
  > div {
    width: 100%;
  }
}

@supports (-ms-ime-align: auto) {
  .gridStyle {
    max-height: calc(100% - 25rem);
  }
}
