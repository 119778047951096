.wrapper {
  display: flex;
  justify-content: space-between;
  flex: 1;
  max-height: 100%;
  width: 100%;
}

.securitiesWrapper {
  display: flex;
  flex-direction: column;
  z-index: 1;
  overflow-x: auto;
  transition: max-width 0.3s ease;
  margin-right: 6rem;
}

.finSecuritiesWrapper {
  display: flex;
  flex-direction: column;
  z-index: 1;
  overflow-x: auto;
  transition: max-width 0.3s ease;
}

.panelWrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow-y: hidden;
}
@supports (-ms-ime-align: auto) {
  .panelWrapper {
    height: 100%;
  }
}

.aggressorAndLastLookwrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  z-index: 100;
  position: fixed;
  right: 34rem;
  pointer-events: none;
  overflow-x: auto;
  max-height: calc(100% - 9.6rem);
  transition: right 0.3s ease;
  @media screen and (min-width: 930px) {
    right: 0;
    left: 26rem;
  }
}

.bondListWrapper {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.2);
  max-width: 500rem;
}

.finWrapper {
  margin-right: 0;
}

.hidden {
  display: none;
}
