@import '../../../assets/scss/variables.scss';

.error {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: height 0.5s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $Orange;
  color: $White;
  padding: 0 1rem;
  z-index: 999;

  .leftWrapper {
    display: flex;
    align-items: center;
  }

  .errorText {
    margin-left: 1rem;
  }

  &.displayed {
    height: 2.4rem;
  }

  &.hidden {
    height: -1px; // this interrupts transition out, fix this
  }
}

.singleError {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $Orange;
  color: $White;
  padding: 0 1rem;
  z-index: 1000;

  .leftWrapper {
    display: flex;
    align-items: center;
  }

  .errorText {
    margin-left: 1rem;
  }

  &.displayed {
    min-height: 2.4rem;
  }

  &.hidden {
    height: 0;
  }
}
