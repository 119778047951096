.message {
  position: fixed;
  top: 0;
  width: 60rem;
  left: calc(50% - 30rem);
  padding: 1rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  animation-duration: 0.3s;
  animation-name: slidein;

  a {
    color: inherit;
  }
}

.errorMessage {
  @extend .message;
  color: #eeeeed;
  background-color: transparentize($color: #c17781, $amount: 0.5);
}

.infoMessage {
  @extend .message;
  color: var(--darkGreenText);
  background-color: #ffffff99;
}

@keyframes slidein {
  from {
    top: -3rem;
  }

  to {
    top: 0;
  }
}
