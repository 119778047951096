@import '../../assets/scss/variables.scss';

.checkBoxAndInput {
  display: flex;
  line-height: 3rem;
}
.inputPreference {
  width: 4.6rem;
  height: 2.1rem;
  border-radius: 0.2rem;
  border: solid 0.2rem $ButtonBorderColor;
  background-color: $core-primary;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  text-align: right;
  padding-right: 0.5rem;
}

.inputPreference::-webkit-outer-spin-button,
.inputPreference::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.inputPreference[type='number'] {
  -moz-appearance: textfield;
}

.inputPreference:disabled {
  background-color: $accent-primary-3;
}
