@import '../../assets/scss/variables.scss';

.wrapper {
  max-width: 115rem;
  height: 100%;
  button {
    display: inline-block;
    margin-right: 2rem;
    margin-bottom: 1rem;
  }
}

.gridWrapper {
  height: calc(100% - 30px);
}

.finWrapper {
  height: calc(100vh - 25px);
}

.closeWindow {
  float: right;
  cursor: pointer;
  -webkit-app-region: no-drag;
  margin: 0.5rem 1rem;
}

.actionIcon {
  position: absolute;
  right: 1rem;
}

.topMenu {
  background-color: white;
  font-size: 1.5rem;
  display: block;
  padding: 0.5rem;
  -webkit-app-region: no-drag;
}

.cb {
  font-size: 2rem;
}

.topMenuSpan {
  margin-right: 2rem;
  input {
    margin-right: 0.3em;
  }
}
