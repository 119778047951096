@import '../../assets/scss/variables.scss';

.button {
  color: $iconColor;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 2.4rem;
  width: 2.4rem;
  justify-content: center;
  transition: transform 300ms;
  border-radius: 1.2rem;
  margin: 0 0.1rem;

  &:hover {
    background-color: $accent-primary-3;
    color: $iconColor;
  }
}

.active {
  background-color: $accent-primary-1;
  border-radius: 1.2rem;
  color: $core-primary;
}

.active:hover {
  background-color: $accent-primary-1;
  color: $iconColor;
}

.myOrdersOpen {
  min-width: 60.7rem;
  display: flex;
  border-left: 2px solid #e4e5e6 !important;
}

.AdvancedFilterEditorWrapper {
  background-color: #f6f6f6;
  display: flex;
}

.AdvancedFilterLeftSide {
  width: 350px;
  display: flex;
  flex: 1;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.nameMenu {
  animation: transitionShow 0.3s ease-out;
  position: absolute;
  top: 2.5rem;
  left: 20rem;
  height: 10rem;
  width: 55.5rem;
  box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.2);
  background-color: #f4f4f4;
  z-index: 1300;
  border: 1px solid #bcbfc2;

  div {
    padding: 1.5rem 1rem 0 2rem;
  }

  footer {
    background-color: #f4f4f4;
    .save {
      border: 1px solid #bcbfc2;
      margin-right: 1.2rem;

      &:hover {
        background-color: #28c988;
        border: 1px solid transparent;
      }
    }
    button {
      float: right;
    }
  }
}

.ResetIcon {
  width: 2rem;
  height: 1.2rem;
  opacity: 0.7;
  font-size: 1.2rem;
  line-height: 2rem;
  color: #1b2c39;
  margin-top: 0.5em;
  cursor: pointer;
}

.save {
  margin-right: 0.5rem;
  background-color: $core-primary;
  font-weight: bold;
  width: 4em;
  border-radius: 0.3em;
  &:hover {
    background-color: $accent-positive-2;
  }
  border: 1px solid #d8d8d8;
}

.resetWrapper {
  margin-right: 0.5rem;
}
.reset {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;

  background-color: $core-primary;
  font-weight: bold;
  width: 4em;
  border-radius: 0.3em;
  &:hover {
    background-color: #e6c0c0;
  }
  border: 1px solid #d8d8d8;
}

.filterActive {
  background-color: #e3a90e;
}

.notDefault {
  border: 1px solid #426097;
  background-color: #dbe3f2;
  border-radius: 0.3em;
}

.input {
  width: 47px;
  height: 26px;
  border-radius: 3px;
  border: solid 1px #d8d8d8;
  background-color: white;
  margin-left: 1rem;
}

.priceYieldStacked {
  width: 24rem;
  margin-left: 0.5rem;
}

.priceYieldLbl {
  width: 5.7rem;
  display: inline-block;
}

.priceYield {
  display: inline-block;
  margin-right: 0.5rem;
  width: 16rem;
  margin-top: 0.2rem;
}

.bidAskDiff {
  display: inline-block;
  margin-right: 0.5rem;
  width: 23rem;
  margin-top: 0.2rem;
}

.yield {
  display: inline-block;
  margin-right: 0.5rem;
  width: 16rem;
}

.size {
  margin-left: 0.5rem;
  display: inline-block;
  padding: 0 0.5em;
}

.myFirmWrapper {
  padding: 0.3em 0.5em;
  margin-left: 0.5rem;
}

.errorMessage {
  padding: 1rem;
  margin-left: 12rem;
  margin-top: 1rem;
  border: 1px solid #bcbfc2;
  box-shadow: 0 0 20px 0 rgba(68, 76, 95, 0.2);
  border-radius: 3px;
  width: 18rem;
  i {
    margin-right: 0.5rem;
  }
}
