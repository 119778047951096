@import '../../assets/scss/variables.scss';

.wrapper {
  position: relative;

  .icon {
    position: absolute;
    left: 0.2rem;
    top: 0.1rem;
  }

  .search {
    box-sizing: border-box;
    width: 100%;
    height: 2.6rem;
    border-radius: 1.3rem;
    border: solid 2px $core-secondary-4;
    background-color: $core-primary;
    line-height: 2.5rem;
    padding-left: 2.2rem;
    padding-right: 0.5rem;

    &::placeholder {
      font-style: italic;
    }
  }

  .clearButton {
    position: absolute;
    right: 0;
    top: 0.1rem;
    background-color: transparent;
    color: $core-primary-5;
  }
}
