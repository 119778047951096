.wrapper {
  margin-bottom: 3rem;

  .subwrapper {
    display: flex;
    align-items: center;
  }
  .normal {
    width: 24px;
    height: 24px;
    object-fit: contain;
    background-color: black;
  }

  .title {
    margin-right: 2rem;
    font-weight: bold;
    height: 2.5rem;
    line-height: 2.5rem;
  }

  .finSearch {
    -webkit-app-region: no-drag;
  }

  .pillwrapper {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    margin: 1rem 0;
    -webkit-app-region: no-drag;
  }

  @media all and (-ms-high-contrast: none) {
    .pillwrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .hrule {
    position: absolute;
    width: 100%;
    left: 0;
    border-bottom: 1px solid #ffffff;
  }
}
