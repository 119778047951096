@import '../../assets/scss/variables.scss';

.badge {
  display: inline-block;
  border-radius: 0.9rem;
  padding: 0.4rem 0.9rem;
}

.badge-red {
  @extend .badge;
  background-color: $BtnCloseAloneRejected;
  color: var(--darkerRedText);
}

.badge-yellow {
  @extend .badge;
  background-color: $LightOrange;
  color: $Orange;
}

.badge-green {
  @extend .badge;
  background-color: $BtnCloseAloneSuccess;
  color: $BottomContentSuccess;
}
