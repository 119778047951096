@import '../../assets/scss/variables.scss';

.footerButtons {
  display: flex;
  width: 100%;
  border-top: 1px solid #d8d8d8;
  -webkit-app-region: no-drag;

  button {
    margin-left: 0 !important;
    text-decoration: none !important;
    color: #687078 !important;
    height: 5.3rem;
  }

  .cancel {
    width: 50%;
    border-radius: 0 0 0 1rem;
    background-color: #f4f4f4;
    &:hover {
      background-color: white;
    }
  }

  .save {
    background-color: $core-primary;
    font-weight: bold;
    width: 50%;
    border-radius: 0 0 1rem 0;
    border: 0;
    &:hover {
      background-color: $accent-positive-2;
    }
    border-left: 1px solid #d8d8d8;
  }
}
