@import '../../assets/scss/variables.scss';

.activityCard {
  background-color: $White;
  padding: 0.7rem 1rem 0.7rem 2rem;
  border-left: 3px solid;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    background-color: $accent-primary-3;
    .actionsWrapper {
      right: 0;
    }
  }

  &.border-red {
    border-color: $darkRedText;
  }

  &.border-blue {
    border-color: $darkBlueText;
  }

  &.border-darkRed {
    border-color: var(--darkerRedText);
  }

  &.border-yellow {
    border-color: #ffce60;
  }

  .time {
    color: $SecondGray;
    float: right;
  }

  .title {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .description {
    line-height: 1.55;
  }

  .descriptionDimmed {
    opacity: 0.65;
  }

  .badgeContainer {
    margin-top: 0.5rem;
  }

  .badge {
    margin-left: -0.9rem;
  }

  .badgeInfo {
    margin-left: 0.5rem;
    color: $SecondGray;
  }
}

.actionsWrapper {
  transition: 0.25s ease-out;
  position: absolute;
  right: -8rem;
  height: 100%;
  width: 8rem;
  top: 0;
  display: flex;
  flex-direction: column;

  .timerWrapper {
    position: absolute;
    right: 10.5rem;
    bottom: 0.5rem;
  }

  .actionButton {
    display: flex;
    flex: 1;
    border: 0;
    height: 5.3rem;
    font-size: 1em;
    color: $White;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 0;

    &.accept {
      background-color: $BottomContentSuccess;
    }
    &.reject {
      background-color: $BottomContentNotAvailable;
    }
    &.cancel {
      background-color: $Orange;
    }
    &.mute {
      background-color: $BottomContentNotAvailable;
      padding: 0;
      &:hover {
        background-color: #4472c4;
      }
    }
    &.showDepth {
      border-bottom: 1px solid;
      background-color: $BottomContentNotAvailable;
      padding: 0;
      &:hover {
        background-color: #4472c4;
      }
    }
  }
}
