@import '../../../assets/scss/variables.scss';
@import '../../../../node_modules/react-querybuilder/dist/query-builder.css';

$primary-color: var(--primaryBackgroundColor);
$secondary-color: var(--secondaryBackgroundColor);

:global {
  .ReactModal__Overlay--after-open {
    z-index: 1000;
  }

  .ag-theme-balham {
    --ag-header-height: 3.5rem;
    &.tiers .ag-ltr {
      border: none !important;

      .ag-header {
        line-height: 1.6;
        opacity: 0.8;
        color: #878f97;
        background-color: transparent;
        border-bottom: none;

        .ag-header-cell {
          border-bottom: 1px solid #f1f1f1;
          padding-left: 0.3rem;
          padding-right: 0;
          font-weight: normal;

          &:first-child {
            padding-left: 1rem !important;
          }
        }

        .ag-header-cell::after {
          border-right: none;
        }

        .ag-header-cell-text {
          flex: 1;
        }

        .ag-header-cell:not(.ag-header-group-cell-no-group) {
          border-top-width: 0;
        }
      }

      .ag-body-viewport,
      .ag-layout-normal {
        overflow-x: hidden;
        -webkit-app-region: no-drag;
      }
    }
  }

  .tiers-grid-row {
    border: 0 !important;

    .ag-cell {
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      font-family: 'Lato', sans-serif;
      font-size: 1.1rem;
      height: 100% !important;
      border-width: 0;
      display: flex;
      justify-content: stretch;
      align-items: center;

      .ag-react-container {
        display: flex;
        flex: 1;
        align-items: center;
      }
    }
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  flex: 1;
  max-height: 100%;
  width: 100%;
}

.hidden {
  display: none;
}

.TiersAdmin {
  padding-left: 1rem;
  label {
    color: white;
  }
}

.CustomerSelect {
  width: 318px;
}

.MainPageCustomerSelect {
  width: 200px;
  margin-bottom: 2rem;
  margin-right: 1rem;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.tiersGridWrapper {
  height: 75%;
}

.selectEdit {
  display: inline-block;
  margin-bottom: 2rem;
  margin-right: 2rem;
  border: 1px solid #d3d3d3;
}

.tiersSearchWrapper {
  width: 20rem;
  margin-bottom: 1rem;
  display: inline-block;
}

.header {
  margin-left: 2rem;
  font-size: 22px;
  align-items: center;
  text-align: center;

  .close {
    color: #b1b1b1;
    background-color: #fff;
    cursor: pointer;
    float: right;
    font-size: 14px;
  }
}

.queryWrapper {
  span {
    label:first-child {
      margin-right: 0.4rem;
    }
    label {
      input {
        margin-right: 0.2rem;
      }
    }
  }
}

.tierWrapper {
  display: flex;
  padding: 1rem 0;
  flex-wrap: wrap;
  justify-content: center;
}

.SingleTierComponent {
  border: 1px solid #b1b1b1;
  min-width: 340px;
  font-size: 13px;
  padding: 1rem;
  margin: 1rem;

  button {
    display: inline;
    margin: 0 0.5rem;
    border: 1px solid;
    .ruleGroup-remove {
      float: right !important;
    }
    .rule-remove {
      float: right !important;
    }
  }

  select {
    margin: 0 0.5rem 0 0;
    border: 1px solid;
  }

  .tierHeading {
    display: flex;
    font-size: 15px;
    font-weight: bold;
    .tierInput {
      box-sizing: border-box;
      width: 50%;
      height: 2.2rem;
      border-radius: 4px;
      border: solid 1px #ccc;
      background-color: #ffffff;
      line-height: 2.5rem;
      padding: 0 1rem;
    }
    svg {
      margin-left: 1rem;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .checkboxes {
    .checkbox {
      margin: 1.5rem 0 2rem;
    }
  }

  .apiUser {
    margin-bottom: 2rem;
  }

  .inputLabel {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }

  .border {
    border: 1px solid #ccc;
    margin: 3rem 0;
  }

  .enabled {
    .AndOrDropdownWrapper {
      margin: 1rem 0;
    }
  }

  .restricted {
    margin-top: 2rem;
  }
}
.addTier {
  margin-left: 2rem;
  color: #fff;
  background-color: #587ccc;
}

.footerButton {
  margin: 0 1rem 1rem 1rem;
  display: inline-block;
  z-index: -100;
}
.footerTierId {
  margin-top: 1rem;
  float: right;
}

.tiersTab {
  color: #fff;
  margin-right: 2rem;
  font-size: 2rem;
  cursor: pointer;
  opacity: 0.3;
  -webkit-app-region: no-drag;
}

.finTiersTab {
  color: initial;
}

.tabSelected {
  opacity: 1 !important;
}

.closeWindow {
  float: right;
  color: initial;
  cursor: pointer;
  margin-right: 2rem;
  -webkit-app-region: no-drag;
}

.finWindow {
  width: 100%;
  background-color: #fff;
}

.tierDiv {
  padding-left: 1rem;
  margin-bottom: 2rem;
}

.revertButton {
  display: inline-block;
  margin-left: 2rem;
  border: 1px solid #d3d3d3;
}

.revertModalButton {
  display: inline-block;
  margin-right: 1rem;
  margin-top: 1rem;
}

.confirmContent {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 15px;
  font-weight: bold;
  line-height: 2rem;
}

.finFooter {
  position: fixed;
  width: calc(100% - 0.4rem);
  bottom: 0;
  -webkit-app-region: no-drag;
}
