@import '../../assets/scss/variables.scss';

.hitLiftButton {
  border-width: 0;
  background-color: transparent;
  font-size: 1.2rem;
  cursor: pointer;
  /*width: 100%;
  height: 100%;*/
  width: 3rem;
  height: 1.9rem;
  text-transform: uppercase;
  line-height: inherit;
  min-height: auto;
  padding: 0 0.25rem;
}

.hitButton {
  @extend .hitLiftButton;
  color: $lightRedText;
  &:hover {
    font-size: 13px;
    color: darken($lightRedText, 25%);
  }
}

.liftButton {
  @extend .hitLiftButton;
  color: $lightBlueText;
  &:hover {
    font-size: 13px;
    color: darken($lightBlueText, 25%);
  }
}

.All {
  background-color: inherit;
}

.HighFillRate {
  background-color: #ffd9f6;
}

.Firm {
  background-color: #bafcff;
}

.CancelButton {
  cursor: pointer;
  text-align: center;
  width: 100%;
}
