@import '../../../assets/scss/variables.scss';

.buy,
.sell {
  font-weight: bold;
}
.buy {
  color: $BuySecondColor;
}
.sell {
  color: $SellSecondColor;
}
.rejected {
  background-color: $BtnCloseAloneRejected;
}
.accepted {
  background-color: $BtnCloseAloneSuccess;
}
.reject {
  background-color: $Gray;
}
