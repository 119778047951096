@import '../../assets/scss/variables.scss';

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: -100px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: transparentize($color: #d1d5d8, $amount: 0.25);
  z-index: 99999;
  cursor: default;
}

.wrapperModal {
  align-self: center;
  background: #fff;
  border-radius: $borderRadiusDefault;
  box-shadow: 0 0 10px 0 rgba(68, 76, 95, 0.2);
  position: relative;
  pointer-events: all;
  width: 37rem;
  height: 40rem;
}
