@import '../../assets/scss/variables.scss';

// todo: this naming isn't optimal - not really global
.globalCard {
  overflow: hidden;
  min-height: 17rem;
  max-height: 100%;
  background-color: $secondaryBackgroundColor;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  flex: 0 1;
}

.popoutCard {
  max-width: 47rem;
  min-height: 19.3rem;
  height: 19.3rem;
  background-color: $secondaryBackgroundColor;
  display: flex;
  flex-direction: column;
  border-radius: 0.3rem;
  position: relative;
  margin-bottom: 1rem;
  border: 1px solid $core-primary-5;
  box-shadow: 1px 1px 4px $core-secondary-5;
}

.popoutAdmin {
  max-width: 70rem;
}

.finPopoutCard {
  min-height: 15.5rem;
  background-color: $secondaryBackgroundColor;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  position: relative;
  border: 1px solid $core-primary-5;
  box-shadow: 1px 1px 4px $core-secondary-5;
  &.windowFront {
    z-index: 101;
  }
}
