@import '../../../assets/scss/variables.scss';

.wrapper {
  align-items: flex-start;
  flex: 1 1 0;
  text-transform: uppercase;
  height: 2.4rem;
  display: flex;
  -webkit-app-region: drag;

  .subwrapper {
    display: flex;
    flex-wrap: wrap-reverse;
    overflow-y: visible;
    align-items: flex-end;
    height: 5.4rem;
  }

  img {
    -webkit-app-region: no-drag;
    cursor: pointer;
  }

  // keeping these separate for now in case they are different in the future

  .labelContainer {
    .labels:first-child,
    .popoutLabels:first-child {
      font-weight: bold;
      margin-bottom: 5px;
    }
    -webkit-app-region: no-drag;
  }

  .addMargin {
    margin-top: 8px;
  }

  .addPopoutMargin {
    margin-top: 6px;
  }

  .adminText {
    text-overflow: ellipsis;
    max-width: 54%;
  }

  .labels,
  .popoutLabels {
    display: flex;
    justify-content: start;
    align-items: center;
    flex: 1 0 0;
    color: $textColor;
  }

  .label,
  .popoutLabel {
    text-align: left;
    margin-right: 5px;
  }

  .labelIssuer,
  .popoutLabelIssuer {
    margin-right: 5px;
  }

  .labelTicker {
    display: flex;
  }
}
