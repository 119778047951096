@import '../../../../assets/scss/variables.scss';

.pill {
  padding: 0 0.5rem;
  height: 2rem;
  border-radius: 1rem;
  font-weight: bold;
  line-height: 2rem;
  text-align: center;
  color: $DarkFontColor;
  text-transform: uppercase;
  cursor: pointer;

  &.active {
    background-color: $ActiveBackgroundColor;
  }
}
