@import '../../assets/scss/variables.scss';

.buttonGroup {
  display: flex;

  .button {
    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left-width: 0;
    }

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.button {
  width: 12rem;
  height: 2.6rem;
  border-radius: 0.4rem;
  border: 1px solid $colorBorder;
  text-transform: uppercase;

  &:hover,
  &:active {
    background-color: #28c988;
  }

  &:disabled {
    background-color: $core-primary-3;
    color: rgba($DarkFontColor, 0.5);
  }

  svg {
    margin-right: 0.7rem;
    display: inline-block;
    vertical-align: middle;
  }
}
