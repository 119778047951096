@import '../../../assets/scss/variables.scss';

.watchlistAdminContainer {
  max-width: 115rem;
  background: $White;
  height: 100%;
  padding: 0.5em;
  .content {
    display: grid;
    grid-template-columns: 65rem minmax(0, 1fr);
    grid-template-rows: auto 1fr;
    height: 100%;
    gap: 0.5rem;
    .editor {
      padding: 1em;
      border-radius: 1rem;
      box-shadow: inset rgba(0, 0, 0, 0.4) 5px 5px 30px -12px;

      display: grid;
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: min-content min-content minmax(200px, 1fr) min-content;
      min-width: 30em;

      &.disabled {
        position: relative;
        :before {
          content: '';
          inset: 0;
          background: rgba(220, 220, 220, 0.1);
          position: absolute;
        }
      }

      h3 {
        font-size: 1.5em;
        line-height: 2;
        font-weight: 900;
      }
      .watchlistName {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        input {
          margin: 0;
          background: white;
        }
        .disabled & input {
          color: gray;
        }
      }
      footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        .uploadBtn {
          border: 1px solid #bcbfc2;
          margin-left: 1rem;
          margin-right: 1.2rem;

          .processingHighlight {
            color: $core-secondary-1;
            font-weight: 900;
          }

          &:hover {
            background-color: #28c988;
            border: 1px solid transparent;
          }
        }

        .cancelBtn {
          padding: 0 0.5rem;

          &:hover {
            background-color: $accent-primary-3;
          }
        }
      }
    }
  }
  header {
    grid-column: 1 / 3; /* span both cols */
    display: flex;
    flex-direction: row;
    gap: 2em;
    align-items: center;
    h2 {
      font-weight: 600;
      font-size: 2rem;
      padding-left: 1em;
    }
    .controls {
      display: flex;
      flex-direction: row;
      padding: 0.5rem;
      gap: 2.2rem;
      .searchWrapper {
        padding-top: 1.2rem;
      }
    }
  }
  .watchlist-manager-grid {
    border: 1px solid $core-secondary-1;
  }
}
.modal {
  display: grid;
  place-content: center;
  font-size: 1.2em;
  > div {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  h3 {
    font-weight: 900;
    font-size: 1.2em;
  }
  button {
    padding: 0.5em;
    align-self: center;
    width: fit-content;
  }
}
