@import '../../../assets/scss/variables.scss';

$inputWidth: 5.5rem;
$inputBorderRadius: 0.3rem;
$inputBorderWidth: 1px;
.wrapper {
  max-width: 100%;
  z-index: 99;
  flex: 1 0 200px;
}

.adminWrapper {
  position: relative;
}

.form {
  display: flex;
  flex-grow: 1;
  justify-content: end;
  align-items: center;
  padding-top: 1.5rem;
  padding-right: 1.5rem;
  gap: 1.5rem;
  svg {
    margin: 0 0.5rem;
    cursor: pointer;
  }
}

.askGroup {
  display: flex;
  flex: 1 0 100px;
  div {
    align-self: center;
  }
}

.label {
  font-weight: bold;
}

.editControls {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  flex: 1 0;
}

input.item {
  background-color: #ffffff;
  width: $inputWidth;
  text-align: left;
  color: #000000;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  height: 2.2rem;
  padding: 0.2rem 0.5rem;

  border: solid $inputBorderWidth $core-primary-3;
  border-right-width: 0;

  &:first-child {
    border-top-left-radius: $inputBorderRadius;
    border-top-right-radius: $inputBorderRadius;
  }
  &:last-child {
    border-right-width: $inputBorderWidth;
    border-top-right-radius: $inputBorderRadius;
    border-bottom-right-radius: $inputBorderRadius;
  }

  &:focus {
    border: solid 2px $BottomContentSuccess !important;
  }

  &::-webkit-inner-spin-button {
    display: none;
  }

  &:focus::placeholder {
    color: transparent !important;
  }

  &::placeholder {
    text-align: left;
    font-weight: bold;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    color: #717171;
  }

  &.hasError {
    border: solid 2px $Orange !important;
    color: $darkRedText;
  }
}

.isPending {
  font-weight: 900;
}
