@import '../../assets/scss/variables.scss';

.gridContainer {
  flex: 1;
  min-width: 50rem;
  width: auto;
  max-width: 100%;
  overflow: hidden;
  transition: width 0.3s;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  display: flex;
  flex-direction: column;
}

.finContainer {
  flex: 1;
  min-width: 50rem;
  width: auto;
  max-width: 100%;
  overflow: hidden;
  transition: width 0.3s;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid $core-primary-5;
}

.expandContent {
  overflow: hidden;
}

.collapsed {
  overflow: hidden;
  min-width: 50rem;
  width: auto;
}

.security > div {
  display: flex;
  justify-content: space-between;
  text-align: right;

  span {
    width: 42px;
  }
}

.textRight {
  text-align: right !important;
  padding-right: 0.3rem !important;
}

.padding {
  &.large {
    padding: 0 12px !important;
  }

  &.medium {
    padding: 0 9px !important;
  }

  &.regular {
    padding: 0 6px !important;
  }

  &.small {
    padding: 0 3px !important;
  }
}

.fullHeight {
  & > div {
    height: 100%;
  }
}

.hideHeaderText {
  color: transparent;
}

.action {
  cursor: pointer;
}

$inputWidth: 5.4rem;

.item {
  text-align: center;
  width: calc($inputWidth + 4px);
  display: grid;
  height: 100%;
  place-content: center;
}

.item input {
  width: $inputWidth;
  text-align: center;
  color: #000000;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
  height: 100%;

  border: solid 2px $core-primary-3 !important;
  border-radius: 3px;
  margin: 0 !important;

  &::-webkit-inner-spin-button {
    display: none;
  }
}

.isPending,
.isPending input {
  font-weight: 900;
}

.hasFocus {
  border: solid 2px $BottomContentSuccess !important;
  background: #fff;
}

.hasError {
  border: solid 2px $Orange !important;
  background: #fff;
}

.infiniteLoadingIndicator {
  text-transform: uppercase;
  font-weight: normal;
}

.bestOrder {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  width: 100%;
  display: block;
  align-self: stretch;
  height: 100%;
  margin: auto;
}

.myOrder {
  background-color: transparentize($color: #8cccb2, $amount: 0.6);
}

:global {
  .issuerCellContainer {
    width: 100%;
  }
}

// advanced order entry form
.benchmark {
  text-align: center;
  padding-top: 1rem;
}
main {
  background-color: $core-primary;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 2rem !important;
  .field {
    flex: 50%;
    padding: 5px;
    display: inherit;
  }
  .advancedLabel {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-align: left;
    line-height: 30px;
  }
  .advanced {
    width: 6rem;
    height: 2.1rem;
    border-radius: 0.2rem;
    border: solid 0.2rem #d8d8d8;
    background-color: #ffffff;
    margin: 0.5rem;
    text-align: center;
    padding-right: 0.5rem;
    &:disabled {
      opacity: 0.5;
    }
  }
  .checkbox {
    width: 3rem;
    height: 1.5rem;
    border-radius: 0.2rem;
    border: solid 0.2rem #d8d8d8;
    background-color: #ffffff;
    margin: 0.8rem 0;
    text-align: center;
    opacity: 0.7;
    cursor: pointer;
  }
}

.close {
  cursor: pointer;
  justify-content: flex-end;
  position: absolute;
  right: 1rem;
  top: 0.8rem;
  width: 3rem;
  padding: 0;
  background-color: $core-primary-1;
  color: $iconColor;
  &:hover {
    opacity: 1;
  }
}

.customHeight {
  height: 15rem !important;
}

.books {
  position: relative;
  display: inline-block;
  background-color: white;
  margin-top: 0.3rem;
  cursor: pointer;
}

.pillwrapper {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  margin: 1rem 0;
  -webkit-app-region: no-drag;
}
