.fieldset {
  --bc: #a4acb2;
  border: 1px solid var(--bc);
  border-radius: 1em;
  padding: 0.5em 1em 1em;
  legend {
    border: 1px solid var(--bc);
    border-radius: 1em;
    padding: 0.5em;
  }
}
