@import '../../../assets/scss/variables.scss';

.tickerWrapper {
  max-width: 115rem;
  background-color: #fff;
  padding-top: 0.5rem;
  height: calc(100% - 7rem);
}

.finDataWrapper {
  width: 100%;
  background-color: #fff;
}

.tickerSearchWrapper {
  width: 20rem;
  margin-bottom: 1rem;
  -webkit-app-region: no-drag;
}

.finWrapper {
  height: 82vh;
  -webkit-app-region: no-drag;
}

.closeWindow {
  float: right;
  cursor: pointer;
  -webkit-app-region: no-drag;
}

.finFooter {
  position: fixed;
  width: calc(100% - 0.4rem);
  bottom: 0;
  -webkit-app-region: no-drag;
}

.datepicker {
  margin-left: 1rem;
  -webkit-app-region: no-drag;
}

.dataSearchWrapper {
  width: 20rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  display: inline-block;
}

.topInputs {
  margin-top: 1rem;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.title {
  font-size: 2rem;
  margin-left: 20rem;
  font-weight: bold;
}

.liquidityTitle {
  font-size: 2rem;
  margin-left: 10rem;
  font-weight: bold;
}

.btn {
  height: 2rem !important;
  display: inline-block;
  margin-left: 1rem;
  -webkit-app-region: no-drag;
}

.exportBtn {
  display: inline-block;
  margin-left: 1rem;
}

.dataGridWrapper {
  margin-top: 1rem;
  border-top: 1px solid;
  -webkit-app-region: no-drag;
}

.Select {
  z-index: 3;
  width: 340px;
  font-size: 13px;
  padding: 0 1rem;
  display: inline-block;

  button {
    display: inline;
    margin: 0 0.5rem;
    border: 1px solid;
    .ruleGroup-remove {
      float: right !important;
    }
    .rule-remove {
      float: right !important;
    }
  }
}
