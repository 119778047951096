@import '../../../../assets/scss/variables.scss';

.afeContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));
  grid-template-rows: repeat(auto-fit, auto);
  gap: 1.5em;
  > div {
    padding: 1.5em 0;
  }
  .hasSelections {
    border: 1px solid #426097;
    background-color: #dbe3f2;
    border-radius: 0.3em;
  }

  .range-fieldset {
    display: inline-flex;
    flex-direction: row;
    gap: 1em;
    input[type='number'] {
      width: 4em;
      padding: 0.1em;
      text-align: center;
      box-shadow: inset $core-secondary-4 2px 2px 10px;
      line-height: 1.2;
    }
  }

  .afe-select-values {
    /*border-bottom: 1px dotted $core-secondary-4;*/
  }
  .afe-set-ranges {
    padding-top: 0.2em;
  }
}
