@import '../../assets/scss/variables.scss';

.dropdown {
  position: relative;
  display: inline-block;
}

.contentDropDownMenu {
  margin: 0;
  padding: 0;
  top: 3.5rem;
  position: absolute;
  width: 27rem;
  background-color: white;
  z-index: 3;
  box-shadow: 0 0.5rem 2.8rem #e5e5e5;
  max-height: 300px;
  overflow-y: scroll;

  svg {
    color: #656c78;
  }
}

.dropdownLabelAndButton {
  display: flex;
  max-width: 20rem;
  width: max-content;
  .btnLabel {
    background-color: #f6f6f6;
    line-height: 2.6rem;
    padding: 0 1.5rem;
    text-align: center;
    border-radius: 2rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .watchlistLabel {
    font-weight: bold;
  }
  .btnDropDown {
    font-weight: bold;
    i {
      margin-left: 0.5rem;
      line-height: 2.6rem;
    }
  }
  .btnDropDown:hover {
    cursor: pointer;
  }
}

.listItem {
  min-height: 2.6rem;
  width: 100%;
  line-height: 2.6rem;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;

  &.clickable,
  &.clickable * {
    cursor: pointer;
  }
  label {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .icnSelected {
    width: 2rem;
  }

  .deleteWatchlist {
    opacity: 0.25;
    background-color: rgba($color: white, $alpha: 0);
    width: 2.5rem;
    display: flex;
    justify-content: center;
    border: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    svg {
      margin: 0;
      padding: 0;
    }
  }

  .handleConfirmation {
    background-color: $Orange;
    color: white !important;
  }

  .btnGrpDeleteWatchlist {
    width: 100%;
    align-items: center;
    .deleteWatchlistConfirmation {
      width: 4rem;
      background-color: rgba($color: #000000, $alpha: 0);
      border: 0;
      border-radius: 0;
      color: white;
    }
    .deleteWatchlistConfirmation:hover {
      background-color: #28c988;
    }
  }

  .displayNameInput {
    border: 2px solid $core-primary-3;
    border-radius: 0.3rem;
    height: 2.5rem;
    padding: 0.5rem;
    width: 16rem;

    &:focus {
      border: 2px solid $BottomContentSuccess;
    }
  }

  .selected {
    font-weight: bold;
  }

  &:hover {
    background-color: #b1bfce;

    .deleteWatchlist {
      opacity: 1;
    }
  }
}

.handleConfirmation {
  background-color: $Orange !important;
  color: white !important;

  .handleConfirmation button {
    color: white !important;
  }
}

.listItem.clickable:hover {
  background-color: $accent-primary-3;
}

.none {
  display: none;
}

.flex {
  display: flex;
}
.removeButton {
  text-transform: uppercase;
  background-color: $core-primary-1;
  border-radius: 0.3rem;
  border: solid 0.1rem $ButtonBorderColor;
}
.removeButton:active,
.removeButton:hover {
  border: solid 0.1rem $BottomContentSuccess;
  background-color: $BottomContentSuccess;
}

.message {
  text-transform: uppercase;
  font-weight: bold;
}

.number {
  font-weight: normal;
  display: inline-block;
  border-radius: 3.5px;
  background-color: $accent-positive-2;
  padding: 0.5rem;
  margin-right: 0.5rem;
}
