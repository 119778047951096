@import '../../../assets/scss/variables.scss';

.systemParamWrapper {
  button {
    display: inline-block;
    margin-right: 2rem;
    margin-bottom: 1rem;
  }
}

.finParamsWrapper {
  width: 100%;
  background-color: #fff;
  button {
    display: inline-block;
    margin-right: 2rem;
    margin-bottom: 1rem;
  }
}

.systemParamSearchWrapper {
  max-width: 20rem;
  margin-bottom: 1rem;
  -webkit-app-region: no-drag;
}

.restrictedGridWrapper {
  max-width: 100rem;
}

.finWrapper {
  height: 80%;
  -webkit-app-region: no-drag;
}

.closeWindow {
  float: right;
  cursor: pointer;
  -webkit-app-region: no-drag;
}

.sysParamForm {
  .criteria {
    display: inline-block;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-bottom-style: groove;
    border-left-style: groove;
    border-left-width: 1px;
    border-right-width: 1px;
    border-right-style: groove;
    border-top-style: groove;
    margin-bottom: 1rem;
    height: 2rem;
    width: 50rem;
  }

  textarea {
    width: 50rem;
    margin: 0 0 1rem 0;
  }
}

footer {
  button {
    &:first-child {
      margin-left: 0;
    }
    display: inline-block;
    margin-right: 1rem;
    margin-top: 1rem;
  }
}

.finFooter {
  position: fixed;
  width: calc(100% - 0.4rem);
  bottom: 0;
  -webkit-app-region: no-drag;
}
