/**
 * App
 */
@import './reset.css';
@import './variables.scss';
@import 'components/scrollbar';
@import 'components/modal';

/**
 * Contrib
 */
@import '@ag-grid-community/styles/ag-grid.css';
@import '@ag-grid-community/styles/ag-theme-balham.css';

/**
 * TODO: Move to more detailed files.
 */
:global {
  @import '~pretty-checkbox/src/pretty-checkbox.scss';

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }
  body,
  html {
    height: 100%;
  }
  html {
    box-sizing: border-box;
    font-size: 10px;
  }

  body {
    font-family: 'Lato', sans-serif;
    color: $textColor;
    background: linear-gradient(#0f1423, #364c63);
    background-attachment: fixed;
    font-size: 1.2rem;
  }

  #app {
    box-sizing: content-box;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  select {
    border-radius: 3px;
    background-color: $core-primary-2;
    height: 2.6rem;
    font-family: inherit;
    text-transform: uppercase;
    color: inherit;
    border-width: 0;
    font-weight: bold;
  }

  button {
    border-width: 0;
    min-height: 2.6rem;
    padding: 0 1rem;
    border-radius: 3px;
    background-color: $core-primary-2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.1rem;
  }
  .error-boundary {
    background: white;
    width: 700px;
    margin: auto;
    padding: 2rem;
    white-space: pre-wrap;
  }
}

input {
  background-color: #ffffff;
  border: 0;
}

.wrapper {
  flex: 1;
  align-self: stretch;
  margin: 0;
  box-sizing: border-box;
  max-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.finColumnWrapper {
  position: relative;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
}

.columnWrapper {
  position: relative;
  max-width: 100%;
  padding-left: 2rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  max-height: calc(100% - 5rem);
  overflow-y: hidden;
}
