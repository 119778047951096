@import '../../assets/scss/variables.scss';

.userEditorForm {
  height: 56rem;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  align-items: stretch;
  background-color: #fff;
}

.customDimensions {
  width: 75rem;
  height: 60rem;
  border-radius: 0;
}

//MAIN SECTION
.fieldset {
  background-color: #eeeeee;
  border: 0.1rem solid #c0c0c0;
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 0.5rem 0;
  display: block;
  width: 75rem;
  -webkit-app-region: no-drag;

  .legend {
    display: block;
    padding-inline-start: 0.2rem;
    padding-inline-end: 0.2rem;
    font-weight: bold;
  }

  .accountContainer {
    display: table;
    border-spacing: 0px 1rem;
    width: 100%;

    .accountRow {
      display: table-row;
    }

    .accountLabel {
      display: table-cell;
      min-width: 10rem;
      padding-left: 0.5rem;
    }

    .accountDropdown {
      display: table-cell;
      .userClassDropdown {
        width: 150px;
        line-height: 2.5rem;
        height: 2.5rem;
        label {
          width: 10rem;
        }
      }
      i {
        line-height: 2.5rem;
      }
    }
  }
  .brokerPercent {
    width: 5.5rem;
  }

  .subAccountContainer {
    table {
      width: 100%;
    }
  }

  .checkboxAccountContainer {
    margin-top: 1.5rem;
  }

  .checkboxContainer {
    display: flex;
    div {
      width: 50%;
    }
    .userEditorCheckbox {
      background-color: white;
    }
  }

  .inputContainer {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 5px;
    label {
      line-height: 4rem;
    }
  }

  input {
    box-sizing: border-box;
    border: solid 1px #a4acb2;
    background-color: #ffffff;
    line-height: 2.5rem;
    margin-left: 0.5rem;
    padding-left: 1rem;
    padding-right: 0.5rem;
  }

  .numberInput {
    width: 5.5rem;
  }
}

.brokerLabel {
  display: inline-block;
  line-height: 2.5rem;
}

.smallFieldsetGroup {
  display: flex;
  .smallFieldset {
    &:first-child {
      margin-left: 0;
    }
    width: 228px;
    margin: 0.5rem 0.3rem;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.grid-item {
  display: flex;
  align-items: center;
}

.input-dropdown {
  margin-right: 10px;
}
