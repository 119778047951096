@import '../../assets/scss/variables.scss';

.header {
  border-bottom: 1px solid #97979733;
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
  background-color: #f4f4f4;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding-left: 2rem;
  line-height: 4rem;
  h2 {
    font-weight: bold;
  }
  button {
    border-top-right-radius: 1rem;
  }
}
