@import '../../assets/scss/variables.scss';

.searchResultItem {
  background-color: $core-primary-2;
  border-top: 1px solid $core-primary;
  border-bottom: 1px solid $core-primary;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  align-items: center;

  &:hover {
    background-color: var(--accent-primary-4);
  }
}

.issuerSymbol {
  display: inline-block;
  width: 6rem;
  margin-right: 0.5rem;
  flex-shrink: 0;
}

.coupon {
  display: inline-block;
  width: 4rem;
  margin-right: 0.5rem;
  flex-shrink: 0;
}

.maturityDate {
  display: inline-block;
  width: 3rem;
  margin-right: 0.5rem;
  text-align: right;
  flex-shrink: 0;
}

.cusipOrIsin {
  display: inline-block;
  width: 10rem;
  margin-right: 1.75rem;
  text-align: right;
  flex-shrink: 0;
}
