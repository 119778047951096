@import '../../../assets/scss/variables.scss';

.titlebarContainer {
  -webkit-app-region: drag;
  flex-direction: row;
  background-color: #fff;
  color: #000;
  font-size: 12px;
  justify-content: space-between;
  display: flex;
}

.finButton {
  border: 0;
  width: 170px;
  padding: 1rem;
  text-transform: capitalize;
  border-radius: 0 0 10px 10px;
  background-color: #fff;
  height: initial;
  &:hover {
    background-color: #f6f6f6;
    border-radius: 1.2rem;
  }
}

.activityContainer {
  -webkit-app-region: no-drag;
  box-shadow: 0 0 20px 4px rgba(68, 76, 95, 0.2);
  margin: 0;
  right: 25;
  top: 4rem;
  position: absolute;
  background-color: #fff;
  z-index: 1000;
  border-radius: 1.2rem;
}

.appTitle {
  margin-top: 0.5rem;
  img {
    height: 3rem;
    margin: 3px 10px 6px 10px;
  }
}

.actions {
  display: flex;
  cursor: pointer;
  margin: 1.6rem 1.1rem 1.1rem 0;
  -webkit-app-region: no-drag;
  float: right;

  .icon {
    font-size: 1.3rem;
    margin-left: 1rem;
    color: #656c78;
  }

  .cancelIcon {
    color: #d1344e;
  }
}

.selectorContainer {
  -webkit-app-region: no-drag;
  margin: 0.5rem 0 0 1rem;
  label {
    color: initial;
  }
}

.marketStatus {
  font-weight: bold;
  font-size: 2rem;
  color: red;
  margin: 0.9rem 0 0 0.5rem;
}

.userName {
  color: #656c76;
  -webkit-app-region: drag;
  cursor: initial;
}

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: transparentize($color: #d1d5d8, $amount: 0.25);
  z-index: 99999;
  cursor: default;
}

.wrapperModal {
  align-self: center;
  background: #fff;
  border-radius: $borderRadiusDefault;
  box-shadow: 0 0 10px 0 rgba(68, 76, 95, 0.2);
  position: relative;
  pointer-events: all;
  width: 35rem;
}

.confirmContent {
  text-align: center;
  margin: 2rem;
  font-size: 2rem;
  font-weight: bold;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  button {
    width: 9rem;
    text-transform: uppercase;
    background-color: $core-primary-1;
    font-weight: bold;
    border-radius: 0.3rem;
    border: solid 0.1rem $ButtonBorderColor;
  }
  .confirm {
    margin-left: 2rem;
  }
  .confirm:active,
  .confirm:hover {
    border: solid 0.1rem $BottomContentSuccess;
    background-color: $BottomContentSuccess;
  }
}
