@import '../../assets/scss/variables.scss';

.container {
  background-color: $core-primary;
  padding: 0.5rem 1.5rem;
  box-shadow: 0 0 1rem #e5e5e5;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.removeButton {
  text-transform: uppercase;
  background-color: $accent-positive-2;
  padding-left: 2rem;
  padding-right: 2rem;
  font-weight: bold;
}

.message {
  text-transform: uppercase;
  font-weight: bold;
}

.number {
  font-weight: normal;
  display: inline-block;
  border-radius: 9.5px;
  background-color: $accent-positive-2;
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
}
