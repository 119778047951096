@import '../../assets/scss/variables.scss';

.button {
  color: $iconColor;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 2.4rem;
  width: 2.4rem;
  justify-content: center;
  transition: transform 300ms;
  border-radius: 1.2rem;
  margin: 0 0.1rem;
  position: relative;

  &:hover {
    background-color: $accent-primary-3;
    color: $iconColor;
  }
}

.active {
  background-color: $accent-primary-1;
  border-radius: 1.2rem;
  color: $core-primary;
}

.active:hover {
  background-color: $accent-primary-1;
  color: $iconColor;
}

.menu {
  margin: 0;
  padding: 0;
  top: 3rem;
  right: 0;
  position: absolute;
  width: 20rem;
  background-color: white;
  z-index: 10;
  box-shadow: 0 0.5rem 2.8rem #e5e5e5;
}

.listItem.clickable:hover {
  background-color: $accent-primary-3;
}

.listItem {
  min-height: 2.6rem;
  width: 100%;
  line-height: 2.6rem;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;

  &.clickable,
  &.clickable * {
    cursor: pointer;
  }
  label {
    flex: 1;
  }

  .icnSelected {
    width: 2rem;

    i {
      color: #28c988;
    }
  }

  .deleteWatchlist {
    opacity: 0.15;
    background-color: rgba($color: white, $alpha: 0);
    width: 2.5rem;
    display: flex;
    justify-content: center;
    border: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    svg {
      margin: 0;
      padding: 0;
    }
  }

  .handleConfirmation {
    background-color: $Orange;
    color: white !important;
  }

  .btnGrpDeleteWatchlist {
    width: 100%;
    align-items: center;
    .deleteWatchlistConfirmation {
      width: 4rem;
      background-color: rgba($color: #000000, $alpha: 0);
      border: 0;
      border-radius: 0;
      color: white;
    }
    .deleteWatchlistConfirmation:hover {
      background-color: #28c988;
    }
  }

  &:hover {
    background-color: #b1bfce;

    .deleteWatchlist {
      opacity: 1;
    }
  }
}

.All {
  background-color: inherit;
}

.HighFillRate {
  background-color: #ffd9f6;
}

.Firm {
  background-color: #bafcff;
}
