@import '../../assets/scss/variables.scss';

.footer {
  padding: 1.8rem 0 0 0;
  flex: 1;
  border-left: 2px solid #e4e5e6 !important;
  min-width: 60.55rem;
}

.title {
  font-size: 1.2rem;
  text-transform: uppercase;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  margin-bottom: -0.2rem;
  margin-top: 0.5rem;
  border-bottom: 0.2rem solid $core-primary;

  .count {
    display: inline-block;
    margin-left: 0.5rem;
  }
}

.buttons {
  padding: 1.3rem 0.7rem 0 0.7rem;
  display: flex;
  justify-content: center;
}

.stopIcon {
  width: 15px;
  margin-right: 0.5rem;
}
