@import '../../../assets/scss/variables.scss';

:global {
  .securityDetailsHeader {
    border-bottom: 1px solid $core-secondary-5;
    padding-bottom: 10px;

    span {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .securityDetailContent {
    padding-top: 20px;
  }

  .benchmarkName {
    font-size: 2rem;
  }

  .dividedContent {
    padding-top: 20px;

    .contentLabels {
      font-weight: 600;
      margin-right: 10px;
      min-width: 160px;
    }

    .contentLabels span,
    .contentValues span {
      display: block;
      margin: 15px 0;
      font-size: 14px;
    }

    .benchmarkName {
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .contentHighlight {
    font-size: 14px;
    padding-top: 170px;
    min-width: 210px;

    .creditRatingsContainer {
      margin-bottom: 30px;

      .contentHighlightContainer {
        width: 60%;
      }
    }

    .contentHighlightSectionLabel {
      font-size: 15px;
      font-weight: 600;
      padding-bottom: 5px;
    }

    .contentHighlightLabel {
      font-weight: 600;
    }

    .contentHighlightLabel span,
    .contentHighlightItem span {
      margin-bottom: 10px;
    }

    .contentHighlightItem span {
      margin-left: 5px;
    }

    .contentHighlightContainer {
      background-color: $accent-primary-3;
      border: 1px solid #a5acb2;
      border-radius: 5px;
      padding: 10px;
    }
  }

  // todo move this into a global area until we have bootstrap in place
  .d-flex {
    display: flex !important;
  }

  .flex-column {
    flex-direction: column !important;
  }

  .flex-row {
    flex-direction: row !important;
  }

  .justify-content-center {
    justify-content: center !important;
  }

  .justify-content-between {
    justify-content: space-between !important;
  }

  .justify-content-start {
    justify-content: start !important;
  }

  .justify-content-end {
    justify-content: end !important;
  }

  .align-items-center {
    align-items: center;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .d-inline {
    display: inline;
  }

  .m-auto {
    margin: auto;
  }
}
