@import '../../assets/scss/variables.scss';

.gridHeaderWrapper {
  background-color: $primaryBackgroundColor;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 100;
}

.finHeaderWrapper {
  background-color: $primaryBackgroundColor;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 100;
}

.addDrag {
  -webkit-app-region: drag;
}

.checkboxWrapper {
  margin: auto 0.5rem auto 0;
}

.gridHeader {
  height: 5rem;
  padding: 0 0.5rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: inherit;
  max-width: 100%;
  flex: 1 0;
}

.adminText {
  -webkit-app-region: drag;
  margin: 1rem 1rem 1rem 0;
  text-overflow: ellipsis;
  max-width: 12.3rem;
  overflow: hidden;
}

.headerButtons {
  //width: 48rem;
  display: flex;
  justify-content: space-between;
  -webkit-app-region: no-drag;

  .closeWatchlist {
    color: #b45161;
    font-size: 15px;
  }
}

.myOrdersHeader {
  display: flex;
  flex: 0 1 58.55rem;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
  text-transform: uppercase;
  border-left: 2px solid #e4e5e6 !important;

  .myOrdersLabel {
    line-height: 3rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    -webkit-app-region: no-drag;
  }

  .stopSignIcon {
    width: 25px;
    margin-left: 5px;
  }

  .myOrdersParameters {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .label {
      line-height: 3rem;
      font-weight: bold;
    }

    .icon {
      line-height: 3rem;
      font-size: 1.5rem;
      cursor: pointer;
      color: $iconColor;
      &:hover,
      &:focus {
        color: $textColor;
      }
    }
  }

  .stopIcon {
    cursor: pointer;
    margin-left: 0.5rem;
    width: 25px;
  }
}

.headerDropdown {
  display: flex;
  align-items: center;

  .addWatchlist {
    color: $DarkFontColor;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
  }

  .addWatchlist:hover,
  .selected {
    background-color: $ActiveBackgroundColor;
    opacity: 1;
  }

  .notSelected {
    background-color: $secondaryBackgroundColor;
    opacity: 0.7;
  }

  & > * {
    margin-right: 0.5rem;
  }
}

.headerAddWatchList {
  margin-left: 0.5rem;
  a:link,
  a:visited {
    color: $DarkFontColor;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    padding: 0.7rem 1.2rem;
    border-radius: 10rem;
  }

  a:not(:last-child) {
    margin-right: 0.5rem;
  }

  a:hover,
  .selected {
    background-color: $ActiveBackgroundColor;
    opacity: 1;
  }

  .notSelected {
    background-color: $secondaryBackgroundColor;
    opacity: 0.7;
  }
}

.headerIcons {
  display: flex;
  align-items: center;
}

.rightBorderAdjust {
  border-radius: 0;
}

.myOrdersOpen {
  transform: rotate(180deg);
}

.dFlex {
  display: flex !important;
  -webkit-app-region: no-drag;
}

@supports (-ms-ime-align: auto) {
  .gridHeader {
    border-right: 2px solid #e4e5e6 !important;
  }
  .myOrdersHeader {
    border-left: 0 !important;
  }
}

.dropdownWatchlistContainer {
  background-color: $core-primary;
  padding: 0.5rem 1.5rem;
  box-shadow: 0 0 1rem #e5e5e5;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 43rem;
  width: 27rem;
}

.finDropdownContainer {
  top: 39rem;
}
