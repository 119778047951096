@import '../../assets/scss/variables.scss';

.webSizing {
  min-width: 800px;
  width: 60%;
  max-width: 900px;
  color: #fff;
}

.finSizing {
  height: 100%;
  background-color: #fff;
  padding: 1rem 0 0 0;
  color: #303330;
  min-width: 700px;
  border: 1px solid #d3d3d3;
  box-sizing: border-box;
}

.benchmarkTitle {
  margin: 0 10px 0 15px;
  padding-top: 8px;
  color: gold;
}

.finTitle {
  color: #4169e1;
  font-weight: bold;
}

.benchmarkContainer {
  display: flex;
  text-align: center;
  margin: 0 auto;
  min-width: 405px;
  justify-content: center;
  -webkit-app-region: drag;
}

.addHeight {
  height: 40px;
}

.benchmarkData {
  font-size: 15px;
  margin: 0 auto;
  padding-top: 0.5rem;
}

.benchmarkYear {
  margin-bottom: 2px;
}

.benchmarkYearLabel {
  font-weight: bold;
}

.benchmarkLabel {
  margin: 0.5rem 0 1rem 0;
  font-size: 12px;
  font-weight: bold;
}

.table {
  margin: 0 auto;
  font-size: 12px;
}

.finFooter {
  height: 0;
  -webkit-app-region: no-drag;
}
