@import '../../../assets/scss/variables.scss';

.buttonsComponents {
  display: flex;
  text-align: center;
  flex: 1;

  button {
    flex: 1;
    height: 5.3rem;
    border-color: $ButtonBorderColor;
    border-style: solid;
    border-width: 1px 0 0 1px;
    font-size: 1.4rem;
    justify-content: center;
    text-transform: uppercase;
  }
}

.btnClose {
  background-color: $BtnCloseColor;
}
.btnSellBuy {
  background-color: $White;
  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    background-color: $BottomContentSuccess;
  }
}

.btnCloseAlone {
  background-color: $White;
}

.btnCloseAloneSuccess {
  background-color: $BtnCloseAloneSuccess;
}

.btnCloseAlonePending {
  background-color: $BtnCloseAlonePending;
}

.btnCloseAloneRejected {
  background-color: $BtnCloseAloneRejected;
}
