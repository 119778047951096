@import '../../assets/scss/variables.scss';

.dropdownBtn {
  background-color: $primaryBackgroundColor;
  border: 0;
  width: 3rem;
  justify-content: flex-end;

  svg {
    margin-right: 0;
    color: #656c78;
  }
}

.dropdownBtn:hover {
  background-color: $primaryBackgroundColor;
  border: 0;
}

.menu {
  margin: 0;
  padding: 0;
  right: 1rem;
  top: 3.5rem;
  position: absolute;
  background-color: $core-primary;
  z-index: 1;
  box-shadow: 0 0 20px 0 rgba(68, 76, 95, 0.2);
  text-transform: none !important;
  .icnSelected {
    color: #28c988;
    width: 2rem;
    float: left;
    height: 2.6rem;
    text-align: center;
    i {
      line-height: 2.6rem;
    }
  }
  .listItem {
    padding-left: 1rem;
  }
}
@media all and (-ms-high-contrast: none) {
  .menu {
    width: 16.5rem;
  }
}
.columnListItemUpload {
  padding-left: 1rem;
  width: 16.5rem;

  .count {
    float: right;
    padding-right: 0.5rem;
  }
  label {
    width: 100%;
  }
}

.dropdownRaw.clickable:hover {
  background-color: $accent-primary-3;
}

.footerButtons {
  display: flex;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: -0.3rem;

  button {
    width: 50%;
    border: solid $core-primary-3;
    border-width: 0.2rem 0.1rem 0;
    border-radius: 0px;
    text-transform: uppercase;

    &:last-child {
      border-bottom-right-radius: 0.3rem;
      border-right-width: 0;
    }
    &:first-child {
      background-color: $core-primary;
      border-bottom-left-radius: 0.3rem;
      border-left-width: 0;
      &:hover {
        background-color: $core-primary;
      }
    }
    &.apply {
      font-weight: bold;
      &:hover {
        background-color: $accent-positive-2;
      }
    }
  }
}
