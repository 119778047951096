@import '../../assets/scss/variables.scss';

.activityWrapper {
  position: fixed;
  right: 0;
  top: 85px;
  bottom: 6.2rem;
  background-color: $Gray;
  width: 34rem;
  overflow: auto;
  box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease;
  display: flex;
  flex-direction: column;

  &.hidden {
    position: fixed;
    right: -32.65rem;
    cursor: pointer;
    &:hover {
      right: -29rem;
    }
  }

  &.windowFront {
    z-index: 100;
  }

  .activityWrapperContent {
    padding: 1.25rem;
    flex: 1;
  }

  .activityListContent {
    margin-top: 1.5rem;
  }

  .activityWrapperHeader {
    padding: 0.25rem;
  }

  .activityWrapperFooter {
    background: black;
    position: fixed;
    bottom: 10px;
  }

  .title {
    text-transform: uppercase;
    color: $BottomContentNotAvailable;
    margin-top: 1.5rem;
    height: auto;
  }

  ul > li:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.finWrapper {
  display: none;
}

.placeholder {
  color: $InputContentLabelColor;
}

.blink {
  animation: blink 2s linear infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
