@import '../../assets/scss/variables.scss';

form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

// TODO: main needs to be referenced from one location.
main {
  .contentContainer {
    display: block;
  }
  flex-grow: 1;
  flex-shrink: 0;
  padding: 2rem;
  background-color: $core-primary;
  label {
    height: 3rem;
    min-width: 12rem;
    margin-right: 0.5rem;
  }
  .lastLookLabel {
    display: flex;
    line-height: 2rem;
    margin-top: 0.5rem;
  }
}

.close {
  cursor: pointer;
  justify-content: flex-end;
  position: absolute;
  right: 1rem;
  top: 0.8rem;
  width: 3rem;
  padding: 0;
  background-color: $core-primary-1;
  color: $iconColor;
  &:hover {
    opacity: 1;
  }
}

.modalMain {
  width: 40rem;
  display: flex;
  flex-direction: column;
  height: auto;
  pointer-events: all;
}

.wrapper {
  padding: 0;
  border-radius: 1rem;
}

.autoStart {
  display: flex;
  margin-top: 0.5rem;
  .picker {
    display: block;
    margin-top: 0.2rem;
  }
  div {
    line-height: 2.5rem;
  }
}
