@import '../../../assets/scss/variables.scss';

.bottom {
  width: 100%;
  height: 100%;

  .bottomContent {
    color: $White;
    width: 100%;
    height: auto;
    min-height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      float: left;
      width: 100%;
    }

    .bottomTextTitle {
      opacity: 0.8;
    }

    .bottomTextContent {
      font-size: 1.3em;
    }
  }

  .bottomContentSuccess {
    background-color: $BottomContentSuccess;
  }

  .bottomContentRejected {
    background-color: $BottomContentRejected;
  }

  .bottomContentPending {
    background-color: $BottomContentPending;
  }

  .bottomContentTimedOut {
    background-color: $BottomContentTimedOut;
  }

  .bottomNotAvailable {
    background-color: $BottomContentNotAvailable;
  }

  .bottomContentCancelAttempt {
    background-color: $BottomContentCancelled;
  }

  .bottomIcn {
    margin: 1rem;
    width: 5rem;
    height: 5rem;
  }

  .bottomText {
    width: 75%;
    padding: 10px 0;

    p {
      margin: 0.2rem;
    }
  }

  .buttonsComponents {
    display: flex;
    text-align: center;

    button:first-of-type {
      border-left-width: 0;
    }

    button {
      flex: 1;
      height: 5.3rem;
      border-color: $ButtonBorderColor;
      border-style: solid;
      border-width: 1px 0 0 1px;
      font-size: 1.4rem;
      justify-content: center;
      border-radius: 0 0 0 0;
      text-transform: uppercase;
    }
  }

  .bottomModalLogo {
    width: 100%;
    background-color: $core-primary-2;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-top: 1px solid $ButtonBorderColor;
    svg {
      height: 1.5rem;
    }
  }
}
