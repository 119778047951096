@import '../../../assets/scss/variables.scss';
@import '../../../../node_modules/react-querybuilder/dist/query-builder.css';

$primary-color: var(--primaryBackgroundColor);
$secondary-color: var(--secondaryBackgroundColor);

:global {
  .ReactModal__Overlay--after-open {
    z-index: 1000;
  }

  .ag-theme-balham {
    --ag-header-height: 3.5rem;
    &.tiers .ag-ltr {
      border: none !important;

      .ag-header {
        line-height: 1.6;
        opacity: 0.8;
        color: #878f97;
        background-color: transparent;
        border-bottom: none;

        .ag-header-cell {
          border-bottom: 1px solid #f1f1f1;
          padding-left: 0.3rem;
          padding-right: 0;
          font-weight: normal;

          &:first-child {
            padding-left: 1rem !important;
          }
        }

        .ag-header-cell::after {
          border-right: none;
        }

        .ag-header-cell-text {
          flex: 1;
        }

        .ag-header-cell:not(.ag-header-group-cell-no-group) {
          border-top-width: 0;
        }
      }

      .ag-body-viewport,
      .ag-layout-normal {
        overflow-x: scroll;
      }
    }
  }

  .tiers-grid-row {
    border: 0 !important;

    .ag-cell {
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      font-family: 'Lato', sans-serif;
      font-size: 1.1rem;
      height: 100% !important;
      border-width: 0;
      display: flex;
      justify-content: stretch;
      align-items: center;

      .ag-react-container {
        display: flex;
        flex: 1;
        align-items: center;
      }
    }
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  flex: 1;
  max-height: 100%;
  width: 100%;
}

.hidden {
  display: none;
}

.TiersAdmin {
  label {
    color: white;
  }
}

.CustomerSelect {
  width: 318px;
}

.MainPageCustomerSelect {
  width: 200px;
  display: inline-block;
  margin-bottom: 2rem;
  margin-right: 1rem;
}

.CustomerLabel {
  margin-right: 1rem;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;

  .close {
    color: #b1b1b1;
    cursor: pointer;
    float: right;
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.SingleTierComponent {
  display: inline-block;
  height: 100%;
  width: 20%;
  overflow-y: scroll;

  button {
    display: inline;
  }

  input[type='radio'] {
    width: 1rem;
    height: 1rem;
  }
}

.footerButton {
  display: inline-block;
  margin-right: 5px;
  margin-top: 3rem;
  z-index: -100;
}

.queueStatsWrapper {
  width: 100%;
  background-color: #fff;
  height: calc(100vh - 36px);
}

.selectEdit {
  display: inline-block;
  margin-right: 1rem;
}

.apiUser {
  margin-bottom: 3rem;
}

.enabled {
  margin-bottom: 3rem;
}

.restricted {
  margin-bottom: 3rem;
}

.checkboxes {
  margin-bottom: 2rem;
}

.tiersSearchWrapper {
  justify-content: space-between;
  margin-bottom: 1rem;
  display: flex;
  -webkit-app-region: drag;

  input {
    -webkit-app-region: no-drag;
  }

  .closeWindow {
    float: right;
    margin-right: 2rem;
    cursor: pointer;
    -webkit-app-region: no-drag;
  }
}

.AndOrDropdownWrapper {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.finFooter {
  position: fixed;
  width: calc(100% - 0.4rem);
  bottom: 0;
  -webkit-app-region: no-drag;
}
