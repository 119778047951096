@import '../../assets/scss/variables.scss';

.toggle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  --end-radius: 0.75em;
  label {
    cursor: pointer;
    position: relative;
    text-align: center;
    border: 1px solid;
    border-right: none;
    padding: 0.5em;
    span {
      cursor: pointer;
    }
    &:first-child {
      border-top-left-radius: var(--end-radius);
      border-bottom-left-radius: var(--end-radius);
    }
    &:last-child {
      border-top-right-radius: var(--end-radius);
      border-bottom-right-radius: var(--end-radius);
      border: 1px solid;
    }
    input {
      position: absolute;
      opacity: 0;
      z-index: -1;
      cursor: pointer;
    }
    &[data-is-selected='true'] {
      background-color: #43a980;
      color: $White;
      font-weight: 900;
      padding: 0.5em;
      border-color: inherit;
    }
  }
}
