@import '../../assets/scss/variables.scss';
@import '../Modal/modal.scss';

.dropdown {
  position: absolute;
  display: inline-block;
}

.contentDropDownMenu {
  animation: transitionShow 0.3s ease-out;
  position: absolute;
  top: -1.5rem;
  left: 5rem;
  height: 60rem;
  width: 60rem;
  box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.2);
  background-color: $core-primary;
  z-index: 1300;
  border: 1px solid #bcbfc2;

  ::-webkit-scrollbar {
    width: 14px;
    height: 14px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 22px;
    background-color: $ButtonBorderColor;
    height: 16px;
    border: 4.5px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.invalidUploadMenuHeight {
  height: 25rem;
  width: 60rem;
}

.finDropDownMenu {
  height: calc(100vh - 10rem);
}

.uploadMenuHeader {
  display: flex;
  gap: 2.5em;
  align-items: center;
  padding: 0.5rem;
  background-color: $core-primary-2;
  border-bottom: 1px solid #bcbfc2;

  &Left {
    flex: 1 1 0;
    white-space: nowrap;
    > span {
      display: flex;
      flex-direction: row;
      gap: 2em;
      margin: 1rem 0 1rem 1.5rem;
      font-style: italic;
      color: #717171;
      font-size: 14px;
      font-weight: 600;
      align-items: center;
      button {
        width: max-content !important;
        background-color: $accent-primary-1;
        color: $core-primary;
        font-style: normal;
      }
    }
  }

  .errorMessage {
    font-weight: bold;
    color: $Orange;
    flex: 1 0 85px;
  }

  &Right {
    display: flex;
    justify-content: space-between;

    span,
    button {
      border-radius: 1rem;
      padding: 0 1rem;
      line-height: 2.6;
      &:hover {
        background-color: $accent-primary-3;
      }
    }

    button {
      margin-left: 1rem;
      margin-right: 1rem;
      width: 5rem;
      border: 0;
      padding: 0 2rem;
    }
  }

  &Invalid {
    display: block;
    margin: 1rem 1.5rem 0 1.5rem !important;
    font-style: normal !important;
    font-size: 13px;
    color: #000 !important;
  }
}

.uploadMenuClear {
  text-transform: uppercase;
  cursor: pointer;
}

.copyIcon {
  cursor: pointer;
}

.uploadMenuFooterButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 5rem;
  background-color: $core-primary-2;
  flex: 0 1;

  &Left {
    display: flex;
    justify-content: space-between;
    margin: 1rem 2rem;

    & > div:first-child {
      margin-right: 1rem;
    }

    select {
      width: 11.5rem;
      border: solid 1px $core-primary-3;
      background-color: $core-primary;
    }

    select:nth-child(2) {
      margin-left: 1rem;
    }
  }
}

.addBorder {
  border-top: 1px solid #bcbfc2;
}

.btn {
  display: inline-block;
  padding: 0.6rem 2.1rem 0.7rem 2.2rem;
  text-transform: uppercase;
  border-radius: 0.5rem;
}

.uploadBtn {
  border: 1px solid #bcbfc2;
  margin-left: 1rem;
  margin-right: 1.2rem;

  .processingHighlight {
    color: $core-secondary-1;
    font-weight: 900;
  }

  &:hover {
    background-color: #28c988;
    border: 1px solid transparent;
  }
}

.cancelBtn {
  padding: 0 0.5rem;

  &:hover {
    background-color: $accent-primary-3;
  }
}

.invalidUploadError {
  display: block;
  margin: 1rem 1.5rem;
  font-style: normal;
  color: #717171;
}

.invalidUploadRow {
  width: 100%;
  border-bottom: 0.3rem solid #f4f4f4;
}

.rightWhiteBorder {
  border-right: 0.3rem solid $core-primary;
}

.tickerInput {
  width: 10rem;
}

.errorInput {
  border: 2px solid $Orange !important;
}

.numInput {
  width: 6rem;
  text-align: right;
}

.createNew {
  padding: 0.8rem 1rem 0.8rem 2.95rem;
  cursor: pointer;
  border-bottom: 0.2rem solid $core-primary-2;

  &:hover {
    background-color: $accent-primary-3;
  }
}

.createNewInput {
  background-color: #f4f4f4;
  padding: 1rem 1rem 0 2rem;

  input {
    border: 2px solid $core-primary-3;
    border-radius: 0.3rem;
    font-weight: bold;
    height: 2.5rem;
    width: 40rem;
    margin-left: 1rem;
    padding: 0.5rem;
    background-color: $LightOrange;

    &:focus {
      border: 2px solid $BottomContentSuccess;
    }
  }

  &.notNew input {
    background-color: $Gray;
  }
}

.dropDownLabels {
  background-color: #f4f4f4;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-start;
  border-top: 1px solid #bcbfc2;

  & > div {
    margin-top: 1.5rem;
    margin-left: 2.15rem;
    color: $SecondGray;
  }

  & > div:nth-child(2) {
    margin-left: 9.3rem;
  }
}

.uploadMenuFooterLeft {
  margin-left: 2rem;

  select {
    width: 11.5rem;
    border: solid 1px $core-primary-3;
    background-color: $core-primary;
  }

  select:nth-child(2) {
    margin-left: 1rem;
  }
}

.hereLink {
  cursor: pointer;
  color: $BuyFirstColor;
  font-weight: bold;
  font-size: 13px;
}

.uploadTableHeader {
  width: 100%;
  .topLabel {
    border-right: 0.3rem solid $core-primary-2;
    border-bottom: 0;
    padding: 10px 5px;
    color: #687078;
    vertical-align: middle;
    span {
      margin-top: 0.5rem;
      display: block;
      font-size: 10px;
    }
  }
  .tickerHeader {
    width: 100px;
  }
  .rowLabel {
    border: 0.3rem solid $core-primary-2;
    border-bottom: 0;
    padding: 10px 5px;
    text-align: center;
    border-top: 0;
    color: #687078;
  }
  .noBorder {
    border: 0;
  }
}

.uploadTable {
  width: 100%;
  overflow: auto;

  flex: 1 1 auto;

  .rightBorder {
    border-right: 0.3rem solid $core-primary-2;
  }
  .rowData {
    text-align: center;
    color: #687078;
    padding: 0.5rem;
  }

  input {
    border: 2px solid transparent;
    border-radius: 0.3rem;
    font-weight: bold;
  }

  input:hover {
    border: 2px solid $core-primary-3;
  }

  input:focus {
    border: 2px solid $BottomContentSuccess;
  }
}
