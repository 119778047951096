@import '../variables.scss';

:global {
  ::-webkit-scrollbar {
    width: 14px;
    height: 14px;
    background-color: var(--primaryBackgroundColor);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 22px;
    background-color: $core-secondary-5 !important;
    height: 16px;
    border: 4.5px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    border-radius: 22px;
    background-color: $core-secondary-4 !important;
    height: 16px;
    border: 4.5px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  ::-webkit-scrollbar-corner {
    background-color: var(--primaryBackgroundColor);
  }
}
