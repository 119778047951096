@import '../../../assets/scss/variables.scss';

$primary-color: var(--primaryBackgroundColor);
$secondary-color: var(--secondaryBackgroundColor);

:global {
  .ReactModal__Overlay--after-open {
    z-index: 1000;
  }

  .ag-theme-balham {
    --ag-header-height: 3.5rem;
    &.aggress-attempts .ag-ltr {
      border: none !important;
      font-size: 14px;
      -webkit-app-region: no-drag;

      .ag-header {
        line-height: 1.6;
        opacity: 0.8;
        color: #878f97;
        background-color: transparent;
        border-bottom: none;

        .ag-header-cell {
          border-bottom: 1px solid #f1f1f1;
          padding-left: 0.3rem;
          padding-right: 0;
          font-weight: normal;
          font-size: 14px !important;

          &:first-child {
            padding-left: 1rem !important;
          }
        }

        .ag-header-cell::after {
          border-right: none;
        }

        .ag-header-cell-text {
          flex: 1;
        }

        .ag-header-cell:not(.ag-header-group-cell-no-group) {
          border-top-width: 0;
        }
      }

      .ag-body-viewport,
      .ag-layout-normal {
        overflow-x: scroll;
      }

      .ag-body-clipper::-webkit-scrollbar {
        display: none;
      }

      .ag-cell {
        font-size: 14px;
      }
    }
  }

  .aggress-attempts-grid-row {
    border: 0 !important;

    .ag-cell {
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      font-family: 'Lato', sans-serif;
      font-size: 10px;
      height: 100% !important;
      border-width: 0;
      display: flex;
      justify-content: stretch;
      align-items: center;

      .ag-react-container {
        display: flex;
        flex: 1;
        align-items: center;
      }
    }
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  flex: 1;
  max-height: 100%;
  width: 100%;
  -webkit-app-region: no-drag;
}

.hidden {
  display: none;
}

.checkboxes {
  color: white;
  margin-bottom: 1rem;

  span {
    display: inline-block;
  }
  label {
    margin-right: 1rem;
  }
}

.finCheckboxes {
  padding: 1rem;
  background-color: #fff;
  color: initial;
  margin-bottom: 0;
}

.finAttemptsContainer {
  width: 100%;
  height: calc(100vh - 32px);
}

.attemptsContainer {
  max-width: 115rem;
  margin: 1rem;
  font-size: 14px;
}
