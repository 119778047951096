:global {
  .react-contexify {
    min-width: 110px !important;
  }
}

.tableGrid {
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(2, 1fr);
  flex: 1 0;
}

.table {
  display: flex;
  flex-direction: column;
  color: #878f97;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 99;

  .title {
    display: flex;
    justify-content: space-between;
    line-height: 2.4rem;
    width: 26rem;

    .item {
      text-align: left;
      width: 5.4rem;
      padding: 0 0.5rem;
    }
  }

  .adminTable {
    margin-left: -2rem;
  }

  .row {
    display: flex;
    justify-content: center;
    height: 2rem;
    border-bottom: 1px solid #ffffff;

    .bid,
    .offer {
      display: flex;
      align-items: center;

      &.myOrder {
        background-color: transparentize($color: #8cccb2, $amount: 0.6);
      }
    }

    .bid {
      color: #b45161;
      margin-left: 25px;
    }

    .adminBid {
      margin-left: 0;
    }

    .offer {
      color: #6a87a9;
      margin-left: 17px;
    }

    .hitLift,
    .size,
    .price,
    .spread {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      /*padding-right: 1rem;
      padding-left: 1rem;*/
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      font-weight: bold;
    }

    .displayName {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      font-weight: bold;
      width: 12rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .hitLift {
      width: 3rem;
    }

    .price,
    .spread,
    .size {
      width: 5.8rem;
    }
  }

  .isRestricted {
    background-color: transparentize($color: #997cce, $amount: 0.6);
  }
}
