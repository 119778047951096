@import '../../assets/scss/variables.scss';

.cookieContainer {
  height: 40px;
  width: 50%;
  min-width: 25rem;
  background-color: #fff;
  margin: 0 auto 10px;
  padding: 10px;
  box-shadow: 0 3px 10px #000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  button {
    width: 9rem;
    text-transform: uppercase;
    background-color: $core-primary-1;
    font-weight: bold;
    border-radius: 0.3rem;
    border: solid 0.1rem $ButtonBorderColor;
    margin-left: 2rem;
  }
  .accept:active,
  .accept:hover {
    border: solid 0.1rem $BottomContentSuccess;
    background-color: $BottomContentSuccess;
  }
}
