@import '../../../assets/scss/variables.scss';

.errorSection {
  display: inline-block;
  color: white;
}

.bbmIsinWrapper {
  display: inline-block;
}

.finBBMISINWrapper {
  -webkit-app-region: no-drag;
  margin: 2rem;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
}

.textArea {
  width: 99%;
  height: calc(100% - 50px);
}

.uploadButton {
  margin-bottom: 1rem;
}

.closeWindow {
  color: initial;
  cursor: pointer;
}

.finFooter {
  position: fixed;
  width: calc(100% - 0.4rem);
  bottom: 0;
  -webkit-app-region: no-drag;
}
