.wrapper {
  display: flex;
  align-items: center;

  &.disabled {
    .boxContainer {
      opacity: 0.5;
    }
  }

  .checkbox {
    display: none;

    &:checked,
    &:indeterminate {
      ~ .checked {
        display: block;
      }

      ~ .checkedHover {
        display: none;
      }

      ~ .normal {
        display: none;
      }
    }

    &:checked:hover,
    &:indeterminate:hover {
      ~ .checkedHover {
        display: block;
      }

      ~ .normal {
        display: none;
      }

      ~ .checked {
        display: none;
      }
    }
  }

  .label {
    display: flex;
    align-items: center;

    .checked {
      display: none;
    }

    .checkedHover {
      display: none;
    }

    .text {
      margin-left: 0.7rem;
      width: 100%;
    }
  }
}
