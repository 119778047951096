@import '../../assets/scss/variables.scss';
.componentInput {
  width: 10rem !important;
  /*border-bottom-width: 1px !important;
  border-top-width: 1px !important;*/
  border: solid 2px $core-primary-3;
}

.QueryWrapper {
  background-color: white;
  width: calc(100vw - 50px);
  padding-top: 1px;
}

.FinQueryWrapper {
  width: 100%;
  background-color: #fff;
  padding-top: 1px;
}

.finTitle {
  display: flex;
  justify-content: space-between;
}

.closeWindow {
  cursor: pointer;
  -webkit-app-region: no-drag;
  margin: 0.5rem 1rem;
}

.componentLabel {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.CheckboxWrapper {
  margin-top: 1rem;
}

.CheckboxWrapper span {
  font-size: 1.5rem;
}

.RunButton {
  margin-top: 1rem;
  width: 10rem;
}

.DatePickerWrapper {
  width: 10rem;
}

.FormWrapper {
  margin-top: 1rem;
  overflow: scroll;
  height: 100%;
  background-color: #fff;
}

.finFormWrapper {
  margin: 1rem;
  height: 99%;
}

.dropdownLabel {
  font-weight: bold;
  font-size: 1.3rem;
}

.form {
  .formInputs {
    margin: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
  }
  table {
    width: 100%;
  }
  td {
    text-align: center;
  }
}
