@import '../../assets/scss/variables.scss';

$primary-color: var(--primaryBackgroundColor);
$secondary-color: var(--secondaryBackgroundColor);

:global {
  .ReactModal__Overlay--after-open {
    z-index: 1000;
  }
  .ag-theme-balham {
    &.activity-log .ag-ltr {
      .ag-header {
        line-height: 1.6;
        opacity: 0.8;
        color: #878f97;
        background-color: transparent;
        border-bottom: none;

        .ag-header-cell {
          border-bottom: 1px solid #f1f1f1;
          padding-left: 0.3rem;
          padding-right: 0;
          font-weight: normal;

          &:first-child {
            padding-left: 1rem !important;
          }
        }

        .ag-header-cell::after {
          border-right: none;
        }

        .ag-header-cell-text {
          flex: 1;
        }

        .ag-header-cell:not(.ag-header-group-cell-no-group) {
          border-top-width: 0;
        }
      }

      .ag-body-viewport,
      .ag-layout-normal {
        overflow-x: hidden;
      }
    }
  }

  .activity-log-grid-row {
    border: 0 !important;

    .ag-cell {
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      font-family: 'Lato', sans-serif;
      font-size: 1.2rem;
      height: 100% !important;
      border-width: 0;
      display: flex;
      justify-content: stretch;
      align-items: center;

      .ag-react-container {
        display: flex;
        flex: 1;
        align-items: center;
      }
    }
  }

  // Contains Activity Log Search and Close Icon
  .activity-log-actions-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3rem;

    .activity-log-close {
      color: #b1b1b1;
      cursor: pointer;
      float: right;
    }

    .activity-log-search {
      width: 17rem;
    }
  }
}

.toggleButton {
  border: 0;
  border-top: 1px solid $ButtonBorderColor;
  height: 2rem;
  width: 34rem;
  border-radius: 0;
  background-color: #fff;

  &:hover {
    background-color: #f6f6f6;
  }
  &.hidden {
    right: -32.65rem;
    cursor: pointer;
    &:hover {
      right: -29rem;
    }
  }
}

.finButton {
  border: 0;
  width: 170px;
  padding: 1rem;
  border-radius: 10px 10px 0 0;
  text-transform: capitalize;
  height: initial;
  &:hover {
    background-color: #f6f6f6;
    border-radius: 1.2rem;
  }
}
