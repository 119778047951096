:global {
  ::-webkit-scrollbar {
    width: 14px;
    height: 14px;
    background-color: var(--primaryBackgroundColor);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 22px;
    background-color: rgba(212, 221, 232, 0.4);
    height: 16px;
    border: 4.5px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  ::-webkit-scrollbar-corner {
    background-color: var(--primaryBackgroundColor);
  }
}
