@import '../../assets/scss/variables.scss';

.default {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  border: 0.5px solid $core-secondary-4;
  legend {
    font-weight: 900;
    background: $core-secondary-5;
    border-radius: 5%;
    padding: 0.5em;
  }
  label {
    cursor: pointer;
    display: block;
    input {
      margin-right: 0.5em;
    }
    span {
      cursor: pointer;
    }
  }
}
