@import '../../assets/scss/variables.scss';

.title {
  width: 100%;
  color: inherit;
  cursor: pointer;
  background-color: $SecurityInformationColor;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  height: 2.7rem;

  i {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.5rem;
  }
}
